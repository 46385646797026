<template>
  <div>
    <div v-if="clients.length == 0">
      {{ $t('errorNoClients') }}
    </div>
    <div class="list">
      <Client
        v-for="client of filteredClients"
        :key="client.uuid"
        :client="client"
        @deleted="clients = clients.filter(c => c.uuid != $event.uuid)"
      />
    </div>
  </div>
</template>

<script>
import Client from './Client'
import { API } from '@/API'

export default {
  name: 'ClientList',
  components: {
    Client
  },
  props: {
    search: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      clients: []
    }
  },
  computed: {
    filteredClients () {
      return this.clients
        .filter((c) => c.name.toLowerCase().includes(this.search.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  async mounted () {
    this.clients = await API.getClients()
  }
}
</script>

<style scoped>
.list {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 20px;

  margin-top: -1px;
  margin-left: -1px;

  & > * {
    flex-basis: calc(100% / 3 - 1px);
  }
}
</style>
