<template>
  <div class="container">
    <LanguageField v-model="selectedLocale" :locales="locales" />
    <div>
      <div class="field">
        <label class="bold">
          {{ $t('emailSenderName') }}
        </label>
        <div class="default-field">
          <template v-if="value && value.customSenderName">
            {{ value.customSenderName[selectedLocale] }}
          </template>
          <template v-else>
            Oeil pour Oeil
          </template>
        </div>
      </div>
      <div class="field">
        <label class="bold">
          {{ $t('emailSubject') }}
        </label>
        <div class="default-field">
          <template v-if="value">
            {{ value.subject[selectedLocale] }}
          </template>
          <DatoI18nText
            v-else
            :val="defaultEmail.subject"
            :locale="selectedLocale"
          />
        </div>
      </div>
      <div class="field">
        <label class="bold">
          {{ $t('emailContent') }}
        </label>
        <div class="default-field">
          <template v-if="value">
            {{ value.content[selectedLocale] }}
          </template>
          <DatoI18nText
            v-else
            class="multiline"
            :val="defaultEmail.content"
            :locale="selectedLocale"
          />
        </div>
      </div>
      <p class="help">{{ $t('emailReadContentHelp') }}</p>
      <div class="button-wrapper">
        <template v-if="settingsRouterName">
          <router-link
            :to="{ name: settingsRouterName }"
            class="button send btn-m black"

            tag="a"
          >
            {{ $t('configure') }}
          </router-link>
        </template>
        <template v-if="readyToSend">
          <button class="button send btn-m green" @click="sendEmail">
            {{ $t('send') }}
          </button>
        </template>
        <Loading v-else />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import DatoI18nText from '@/components/DatoI18nText'
import LanguageField from '@/components/LanguageField'

export default {
  name: 'EmailForm',
  components: {
    DatoI18nText,
    LanguageField,
    Loading
  },
  props: {
    readyToSend: {
      type: Boolean,
      required: false,
      default: true
    },
    defaultEmail: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: false,
      default: null
    },
    locales: {
      type: Array,
      required: true
    },
    sendEmail: {
      type: Function,
      required: false,
      default: null
    },
    settingsRouterName: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      selectedLocale: '',
      updateLoading: false,
      error: ''
    }
  },
  created () {
    this.selectedLocale = this.locales[0]
  }
}
</script>

<style scoped>
.mode-select-container {
  display: flex;
  justify-content: space-between;

  & p {
    margin: 0;
  }

  & .button {
    margin: 0;
    font-size: 16px;
    padding-left: 7px;
    padding-right: 7px;
  }

  margin-bottom: 10px;
}

label {
  cursor: pointer;
  margin-bottom: 5px;
}

.multiline {
  white-space: pre-line;
}

.default-field {
  background: #eaeaea;
  border: 1px solid #b3b3b3;
  padding: 7px 10px;

  & label {
    display: block;
    margin-bottom: 5px;
  }
}

.field {
  margin-bottom: 20px;
}

.button.send  {
    margin: 20px 0;
    margin-top: 50px;
  }
.button-wrapper {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
.help {
  font-size: 14px;
  margin: 0;
  color: grey;
}
</style>
