<template>
  <div class="markdown-editor">
    <textarea
      ref="textarea"
      :value="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import EasyMDE from 'easymde'
import DOMPurify from 'dompurify'

export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      easyMDE: null
    }
  },
  watch: {
    value () {
      if (this.value !== this.easyMDE.value()) {
        this.easyMDE.value(this.value)
      }
    }
  },
  mounted () {
    this.easyMDE = new EasyMDE({
      element: this.$refs.textarea,
      indentWithTabs: true,
      promptURLs: true,
      spellChecker: false,
      previewClass: 'editor-preview c-content',
      toolbar: [
        'bold', 'italic', 'heading', '|',
        'code', 'quote', '|',
        'unordered-list', 'ordered-list', '|',
        'link', 'image', '|',
        'preview', 'fullscreen'
      ],
      renderingConfig: {
        sanitizerFunction: (html) => DOMPurify.sanitize(html)
      }
    })

    this.easyMDE.codemirror.on('change', (_, change) => {
      if (change.origin === 'setValue') { return }
      this.$emit('input', this.easyMDE.value())
    })
  },
  beforeUnmount () {
    if (!this.easyMDE) { return }
    this.easyMDE.toTextArea()
    this.easyMDE.cleanup()
    this.easyMDE = null
  }
}
</script>

<style>
@import "~easymde/dist/easymde.min.css";
</style>
