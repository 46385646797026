<template>
  <div>
    <form
      ref="form"
      @submit.prevent="submit"
    >
      <input
        v-model="form.email"
        type="email"
        name="email"
        :placeholder="$t('email')"
        required
      >
      <input
        v-model="form.firstName"
        type="text"
        name="first_name"
        :placeholder="$t('firstName')"
        required
      >
      <input
        v-model="form.lastName"
        type="text"
        name="last_name"
        :placeholder="$t('lastName')"
        required
      >
      <input
        v-model="form.branch"
        type="text"
        name="branch"
        :placeholder="$t('branch')"
      >
      <LanguageInput v-model="form.language" />
      <div class="timezone-selector">
        <p>{{ $t('timezone') }}</p>
        <TimezoneInput v-model="form.timezone" />
      </div>
      <MultiSelectLearning
        v-if="multiLearning !== null"
        :learnings="multiLearning"
        @input="packs = $event"
      />
      <div
        v-if="validationError"
        class="error"
      >
        {{ validationError }}
      </div>
      <button
        :disabled="isProcessing"
        class="button rounded black submit"
      >
        <span v-if="!isProcessing">{{ $t('add') }}</span>
        <span v-else>{{ $t('loading') }}</span>
      </button>
    </form>
  </div>
</template>

<script>
import MultiSelectLearning from './MultiSelectLearning'
import LanguageInput from '@/components/data/LanguageInput'
import TimezoneInput from '@/components/data/TimezoneInput'

export default {
  name: 'Individual',
  components: {
    MultiSelectLearning,
    TimezoneInput,
    LanguageInput
  },
  props: {
    isProcessing: {
      type: Boolean,
      required: true
    },
    multiLearning: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      form: {
        email: '',
        firstName: '',
        lastName: '',
        language: '',
        timezone: '',
        branch: ''
      },
      packs: [],
      validationError: ''
    }
  },
  created () {
    this.$parent.$on('success', this.resetData)
  },
  beforeDestroy () {
    this.$parent.$off('success', this.resetData)
  },
  methods: {
    resetData () {
      this.form.email = ''
      this.form.firstName = ''
      this.form.lastName = ''
      this.form.language = ''
      this.form.branch = ''
      // reset other fields (child components listen to parent's event)
      this.$emit('reset-form')
    },
    submit () {
      this.validationError = ''
      if (this.multiLearning !== null && this.packs.length === 0) {
        this.validationError = this.$t('errorFormMissingLearning')
        return
      }
      const { form } = this
      const users = [{
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
        language: form.language,
        // empty branch should be undefined
        branch: form.branch !== '' ? form.branch : undefined,
        timezone: form.timezone !== '' ? form.timezone : 'Etc/UTC'
      }]
      const packs = this.packs
      this.$emit('submit', { users, packs })
    }
  }
}
</script>

<style scoped>
form {
  max-width: 300px;
  margin: 0 auto;

  & > * {
    width: 100%;
    box-sizing: border-box;
  }

  & select {
    background-color: white;
    display: block;
  }

  & .submit {
    margin-top: 20px;
  }

  & .error {
    margin-top: 10px;
    color: red;
  }
}

.timezone-selector {
  border-radius: 0;
  border: 1px solid #c3c3c3;
  margin: 5px auto;
  margin-bottom: 5px;
  margin-bottom: 10px;
  padding: 5px 7px;
  font-size: 17px;
  background: white;

  & p {
    margin: 0;
  }
}
</style>
