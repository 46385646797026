<template>
  <Loading v-if="progress === null" />
  <div v-else>
    <div class="stats">
      <tr>
        <td>{{ $t('recordsAverageScore') }}</td>
        <td>{{ average('score', progress) }}%</td>
      </tr>
      <tr>
        <td>{{ $t('recordsAverageTime') }}</td>
        <td><ElapsedTime :time="Math.round(average('elapsedTime', progress))" /></td>
      </tr>
    </div>
    <div class="header">
      <!--<button @click="selectFiltered">Select All</button>-->
      <!--<button @click="unselectFiltered">Unselect All</button>-->
      <input
        v-model="search"
        type="text"
        :placeholder="$t('searchUsersPlaceholder')"
      >
      <select v-model="filterStatus">
        <option value="">
          -- {{ $t('filterStatusPlaceholder') }} --
        </option>
        <option value="initial">
          {{ $t('recordsNotStarted') }}
        </option>
        <option value="in progress">
          {{ $t('recordsInProgress') }}
        </option>
        <option value="completed">
          {{ $t('recordsDone') }}
        </option>
      </select>
    </div>
    <table class="pack-progress">
      <thead>
        <tr>
          <!--<th />-->
          <th>{{ $t('email') }}</th>
          <template v-if="userHasName">
            <th>{{ $t('name') }}</th>
          </template>
          <template v-if="userHasBranch">
            <th>{{ $t('branch') }}</th>
          </template>
          <th>{{ $t('recordsStatus') }}</th>
          <th>
            {{ $t('diplomaSent') }}
          </th>
          <th>{{ $t('recordsProgression') }}</th>
          <th>{{ $t('recordsScore') }}</th>
          <th>{{ $t('recordsTime') }}</th>
          <th>{{ $t('actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="p of paginatedFilteredProgress"
          :key="p.userUUID"
          :class="[ 'row', { checked: selectedUsers[p.userUUID] === true } ]"
        >
          <!--
          <td>
            <input
              type="checkbox"
              :value="p.userUUID"
              name="selection"
              :checked="selectedUsers[p.userUUID] === true"
              @change="selectUser(p.userUUID)"
            >
          </td>
          -->
          <td :title="p.userUUID">
            <router-link
              class="learner-link"
              :to="{ name: 'learner-detail', params: { learnerId: p.userUUID } }"
            >
              {{ getUser(p).email }}
            </router-link>
          </td>
          <template v-if="userHasName">
            <td>
              {{ getUser(p).firstName }} {{ getUser(p).lastName }}
            </td>
          </template>
          <template v-if="userHasBranch">
            <td>
              {{ getUser(p).branch }}
            </td>
          </template>
          <td>
            <RecordStatus :status="p.status" />
          </td>
          <td>
            <img
              v-if="p.diplomaSent"
              class="action-icon"
              src="@/assets/complete.svg"
            >
            <img
              v-else
              class="action-icon"
              src="@/assets/fail.svg"
            >
          </td>
          <td>
            {{ Math.trunc(p.progress) }}%
          </td>
          <td>
            {{ Math.trunc(p.score) }}%
          </td>
          <td>
            <ElapsedTime :time="p.elapsedTime" />
          </td>
          <!-- Not enough space to show this -->
          <!--
          <td>
            {{ p.startDate.Time }}
          </td>
          <td>
            {{ p.firstSuccessDate.Time }}
          </td>
          <td>
            {{ p.lastSuccessDate.Time }}
          </td>
          -->
          <td class="user-action-container">
            <LearnerLogin
              :login-mode="pack.loginMode"
              :learning-url="learningUrl"
              :user="usersMap[p.userUUID]"
            />
            <button
              class="button action-btn alt-blue"
              :title="$t('sendInvitation')"
              @click="sendInvitation(pack.uuid, p.userUUID, getUser(p).email)"
            >
              <Loading
                v-if="loadingSendInvitation[p.userUUID]"
                class="small-loading loading-black"
              />
              <img
                v-else
                class="action-icon"
                src="@/assets/paper-plane-white.svg"
              >
            </button>
            <button
              v-if="isDiplomaAvailable(p)"
              class="button action-btn alt-green"
              :disabled="loadingSendDiploma[p.userUUID] || successSendDiploma[p.userUUID]"
              @click="sendDiploma(pack.uuid, p.userUUID)"
            >
              <Loading
                v-if="loadingSendDiploma[p.userUUID]"
                class="small-loading loading-black"
              />
              <img
                v-else-if="successSendDiploma[p.userUUID]"
                class="action-icon"
                src="@/assets/icon-check-blue.svg"
              >
              <img
                v-else
                class="action-icon"
                src="@/assets/paper-plane-white.svg"
                :title="$t('sendDiploma')"
              >
            </button>
            <a
              v-if="isDiplomaAvailable(p)"
              :href="getDiplomaURL(p.userUUID)"
              class="button alt-green action-btn"
              target="_blank"
              :title="$t('seeDiploma')"
            >
              <img
                class="action-icon"
                src="../../assets/attestation.svg"
              >
            </a>
            <button
              class="button red action-btn"
              :disabled="loadingDelete[p.userUUID]"
              :title="$t('delete')"
              @click="deleteUserProgress(p.userUUID)"
            >
              <Loading
                v-if="loadingDelete[p.userUUID]"
                class="small-loading loading-white"
              />
              <img
                v-else
                class="action-icon"
                src="@/assets/trash.svg"
              >
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="progress-footer">
      {{ filteredProgress.length }} {{ $t('users') }}
    </div>
    <Pagination v-model="currentPage" :page-count="pageCount" />
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import Pagination from '@/components/Pagination'
import ElapsedTime from '@/components/data/ElapsedTime.vue'
import RecordStatus from '@/components/data/RecordStatus'
import LearnerLogin from '@/components/LearnerLogin'
import { API } from '@/API'

export default {
  name: 'PackUsers',
  components: {
    Loading,
    ElapsedTime,
    Pagination,
    RecordStatus,
    LearnerLogin
  },
  props: {
    pack: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: true
    },
    theme: {
      type: Object,
      required: true
    },
    usersMap: {
      type: Object,
      required: true
    },
    learningUrl: {
      type: String,
      required: true
    },
    progress: {
      type: Array,
      required: false,
      default: null
    }
  },
  data () {
    return {
      currentPage: 1,
      selectedUsers: {},
      loadingSendInvitation: {},
      loadingDelete: {},
      loadingSendDiploma: {},
      successSendDiploma: {},
      search: '',
      filterStatus: ''
    }
  },
  computed: {
    userHasBranch () {
      if (!this.usersMap) {
        return undefined
      }
      const user = Object.values(this.usersMap).find(u => u.branch !== null)
      return user !== undefined
    },
    // Returns true if (at least) one user has a firstName or lastName
    userHasName () {
      if (!this.usersMap) {
        return undefined
      }
      const user = Object.values(this.usersMap).find(u => u.firstName !== '' || u.lastName !== '')
      return user !== undefined
    },
    filteredProgress () {
      return this.filterProgressBySearch(this.filterProgressByStatus(this.progress))
    },
    paginatedFilteredProgress () {
      const offset = (this.currentPage - 1) * this.pageLength
      return this.filteredProgress.slice(offset, offset + this.pageLength)
    },
    pageLength () {
      return 50
    },
    pageCount () {
      return Math.ceil(this.filteredProgress.length / this.pageLength)
    }
  },
  watch: {
    search () {
      this.currentPage = 1
    },
    filterStatus () {
      this.currentPage = 1
    }
  },
  async mounted () {
    this.loading = true
    this.loading = false
  },
  methods: {
    getUser (progress) {
      if (!this.usersMap || !this.usersMap[progress.userUUID]) {
        return {}
      }
      return this.usersMap[progress.userUUID]
    },
    filterProgressBySearch (progress) {
      if (!this.search) {
        return progress
      }
      return progress.filter(p => {
        const user = this.getUser(p)
        const fields = [
          user.email,
          user.firstName,
          user.lastName
        ]
        // Remove special characters to improve search
        const clean = input => input.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        return fields.some(f => clean(f).includes(clean(this.search)))
      })
    },
    filterProgressByStatus (progress) {
      if (!this.filterStatus) {
        return progress
      }
      return progress.filter(p => p.status === this.filterStatus)
    },
    selectUser (uuid) {
      if (this.selectedUsers[uuid]) {
        this.$delete(this.selectedUsers, uuid)
      } else {
        this.$set(this.selectedUsers, uuid, true)
      }
    },
    selectFiltered () {
      this.filteredProgress.forEach(p => {
        this.$set(this.selectedUsers, p.userUUID, true)
      })
    },
    unselectFiltered () {
      this.filteredProgress.forEach(p => {
        this.$delete(this.selectedUsers, p.userUUID)
      })
    },
    average (key, array) {
      if (!array || !array.length) {
        return 0
      }
      let res = 0
      for (const e of array) {
        res += e[key]
      }
      return (res / array.length).toFixed(2)
    },
    isDiplomaAvailable (userProgress) {
      // only users who completed the learning can have the diploma
      if (userProgress.status !== 'completed') {
        return false
      }
      // only users with a language / firstName / lastName can see the diploma
      // users without language / firstName / lastName can exist if the module
      // was previously configured with loginMode === 'email'
      const user = this.usersMap[userProgress.userUUID]
      if (!user.language || !user.firstName || !user.lastName) {
        return false
      }
      return true
    },
    getDiplomaURL (userID) {
      const user = this.usersMap[userID]
      const p = this.progress.find(p => p.userUUID === userID)
      return 'https://pdfgen.oeilpouroeil-learning.com/diploma?' + new URLSearchParams({
        locale: user.language,
        timezone: user.timezone,
        name: user.firstName + ' ' + user.lastName,
        datoDiplomaId: this.theme.diploma.id,
        datoClientId: this.client.datoClientId,
        date: p.firstSuccessDate.Time
      }).toString()
    },
    async sendDiploma (packID, userID) {
      const msg = this.$t('confirmSendDiploma').replace(/\{\{\s?email\s?\}\}/, this.usersMap[userID].email)
      this.$dialog.confirm(msg, {
        okText: this.$t('confirmOk'),
        cancelText: this.$t('confirmCancel')
      }).then(() => {
        this.$set(this.loadingSendDiploma, userID, true)
        API.sendDiploma(packID, [userID])
          .then(() => {
            const p = this.progress.find(p => p.userUUID === userID)
            p.diplomaSent = true
            this.$toast.success(this.$t('attestationSended'))
          })
          .catch(e => {
            this.$toast.error(`Error: ${e}`)
          })
          .finally(() => this.$delete(this.loadingSendDiploma, userID))
      })
    },
    async sendInvitation (packID, userUUID, email) {
      this.$dialog.confirm(this.$t('confirmUserSendInvitation').replace('{{ email }}', email), {
        okText: this.$t('confirmOk'),
        cancelText: this.$t('confirmCancel')
      })
        .then(() => {
          this.$set(this.loadingSendInvitation, userUUID, true)
          API.inviteSelectedUsers(packID, [userUUID])
            .then(() => {
              this.$toast.success(this.$t('userInvited'))
            })
            .catch(e => {
              this.$toast.error('Error: ' + e)
            })
            .finally(() => {
              this.$delete(this.loadingSendInvitation, userUUID)
            })
        })
    },
    async deleteUserProgress (userUUID) {
      this.$dialog.confirm(this.$t('confirmUserProgressDeletion'), {
        okText: this.$t('confirmOk'),
        cancelText: this.$t('confirmCancel')
      })
        .then(() => {
          this.$set(this.loadingDelete, userUUID, true)
          API.deletePackUser(this.pack.uuid, userUUID)
            .then(() => {
              this.$toast.success(this.$t('deletedUser'))
              this.$emit('deleted', userUUID)
            })
            .catch(e => {
              this.$toast.error(`Error: ${e}`)
            })
            .finally(() => {
              this.$delete(this.loadingDelete, userUUID)
            })
        })
    }
  }
}
</script>

<style scoped>
table {
  width: 100%;
  text-align: left;

  /* Allows tbody to have border */
  border-collapse: collapse;

  & thead {
    margin-bottom: 10px;

    & th {
      position: sticky;
      top: 0;

      padding: 10px 7px;
      text-transform: uppercase;
      background: white;
    }
  }

  & tbody {
    padding: 10px;

    max-height: 200px;

    & tr:first-child td {
      padding-top: 9px;
    }

    & tr:last-child td {
      padding-bottom: 9px;
    }

    & td {
      padding: 5px 7px;
    }
  }
}

.stats {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 18px;

  & td:first-child {
    font-weight: bold;
    padding-right: 40px;
    padding-bottom: 15px;
  }
}

.pack-progress {
  width: 100%;
}

.header {
  display: flex;
  justify-content: flex-start;

  margin-top: 20px;
  margin-bottom: 20px;

  & > * {
    margin: 0 !important;
  }

  & > *:not(:last-child) {
    margin-right: 20px !important;
  }
}

.row.checked {
  background-color: var(--primary-yellow-color);
}

.user-action-container {
  display: flex;
  justify-content: flex-end;

  & .action-btn {
    margin: 5px 0;
  }
}

.external-link {
  background-color: var(--primary-yellow-color);
}

.action-btn {
  display: block;
}

.progress-footer {
  margin-top: 10px;
  text-align: right;
}

.learner-link {
  text-decoration: none;
}
.circular-charts {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
}
</style>
