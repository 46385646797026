<template>
  <LearningLayout @theme="theme= $event" @pack="pack = $event">
    <Loading v-if="!theme" />
    <div v-else class="center-container">
      <LearningMenu :theme="theme" />
      <LearningSettingsMenu :theme="theme" />
      <div class="container">
        <ReminderTemplateList :pack-id="pack.uuid" :templates="templates" @delete="templateDeleted" />
        <ReminderRuleList :pack-id="pack.uuid" :templates="templates" />
      </div>
    </div>
  </LearningLayout>
</template>

<script>
import Loading from '@/components/Loading'
import LearningLayout from '@/components/LearningLayout'
import LearningMenu from '@/components/LearningMenu'
import LearningSettingsMenu from '@/components/LearningSettingsMenu'
import ReminderTemplateList from '@/components/ReminderTemplateList'
import ReminderRuleList from '@/components/ReminderRuleList'

import { API } from '@/API'

export default {
  name: 'LearningSettingsReminders',
  components: {
    Loading,
    LearningLayout,
    LearningMenu,
    LearningSettingsMenu,
    ReminderTemplateList,
    ReminderRuleList
  },
  data () {
    return {
      pack: null,
      theme: null,
      templates: null
    }
  },
  watch: {
    async pack () {
      if (this.pack !== null) {
        this.templates = await API.getReminderTemplates(this.pack.uuid)
      }
    }
  },
  methods: {
    templateDeleted (uuid) {
      this.templates = this.templates.filter(t => t.uuid !== uuid)
    }
  }
}
</script>
