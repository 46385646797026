<template>
  <button
    class="container button nostyle"
    @click="changeLang"
  >
    <div class="lang-code">
      {{ $i18n.locale }}
    </div>
    <img
      class="icon"
      src="@/assets/icon-language.svg"
    >
  </button>
</template>

<script>
import { API } from '@/API'

export default {
  name: 'LanguageSwitch',
  methods: {
    changeLang () {
      const all = this.$i18n.availableLocales
      const curr = this.$i18n.locale
      const idx = all.indexOf(curr)
      const nextLocale = all[(idx + 1) % all.length]
      this.$i18n.locale = nextLocale

      // Set it on API
      // To send emails
      if (this.$store.state.logged) {
        const accountId = this.$store.state.account.uuid
        API.updateAccount(accountId, { lang: this.$i18n.locale })
      }

      // Set it in local storage
      // For later usage
      localStorage.setItem('lang', this.$i18n.locale)

      // Remove query params
      // (Inserted in emails links)
      if (this.$route.query.lang) {
        const getOtherQuery = ({ lang, ...other }) => other
        this.$router.replace({ query: getOtherQuery(this.$route.query) })
      }
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  font-size: 16px;

  & > * {
    margin: 3px;
  }
}

.icon {
  height: 17px;
}

.lang-code {
  text-transform: uppercase;
}
</style>
