<template>
  <div>
    <h3>{{ $t('reminderTemplatesTitle') }}</h3>
    <div class="list">
      <Loading v-if="templates === null" />
      <template v-else>
        <div v-if="templates.length === 0" class="empty-list">
          {{ $t('emptyReminderTemplates') }}
        </div>
        <table v-else class="template-table">
          <tbody>
            <tr v-for="t of templates" :key="t.uuid">
              <td class="name">{{ t.name }}</td>
              <td>
                <div class="actions">
                  <button class="button blue action-btn" @click="templateSend = t">
                    <img class="action-icon" src="@/assets/paper-plane-white.svg">
                  </button>
                  <router-link
                    class="button yellow action-btn"
                    :to="{ name: 'pack-settings-reminder-update', params: { templateId: t.uuid }}"
                  >
                    <img class="action-icon" src="@/assets/icon-pencil.svg">
                  </router-link>
                  <button
                    class="button red action-btn"
                    :disabled="deleteLoading"
                    :title="$t('delete')"
                    @click="deleteTemplate(t)"
                  >
                    <Loading v-if="deleteLoading" class="small-loading loading-white" />
                    <img v-else class="action-icon" src="@/assets/trash.svg">
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <router-link
          class="button green btn-m"
          :to="{ name: 'pack-settings-reminder-create', params: { packId: packId } }"
        >
          {{ $t('addReminderTemplate') }}
        </router-link>
      </template>
    </div>
    <Modal
      :title="$t('sendReminderTitle')"
      :open="templateSend !== null"
      @close="templateSend = null"
    >
      <SendReminder
        v-if="templateSend !== null"
        :pack-id="packId"
        :template="templateSend"
        @close="templateSend = null"
      />
    </Modal>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import { API } from '@/API'
import * as Sentry from '@sentry/vue'

import Modal from '@/components/Modal'
import SendReminder from '@/components/SendReminder'

export default {
  name: 'ReminderTemplateList',
  components: {
    Loading,
    Modal,
    SendReminder
  },
  props: {
    packId: {
      type: String,
      required: true
    },
    templates: {
      type: Array,
      required: false,
      default: null
    }
  },
  data () {
    return {
      deleteLoading: false,
      templateSend: null
    }
  },
  methods: {
    async deleteTemplate (template) {
      try {
        await this.$dialog.confirm(this.$t('confirmUserDeletion'), {
          type: 'hard',
          verification: template.name,
          verificationHelp: this.$t('confirmHelp'),
          okText: this.$t('confirmOk'),
          cancelText: this.$t('confirmCancel')
        })
      } catch (e) {
        return
      }
      this.deleteLoading = true
      try {
        await API.deleteReminderTemplate(template.uuid)
      } catch (e) {
        console.error(e)
        Sentry.captureException(e, { tag: 'apiResponse', route: 'delete-reminder-template' })
        this.$toast.error(`Error: ${e}`)
        return // do not emit
      } finally {
        this.deleteLoading = false
      }
      this.$emit('delete', template.uuid)
    }
  }
}
</script>

<style scoped>
.template-table {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.name {
  width: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.actions > * {
  margin: 0;
}
</style>
