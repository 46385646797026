<template>
  <span v-if="time.Valid">{{ formatted }}</span>
  <span v-else>{{ $t('never') }}</span>
</template>

<script>
export default {
  name: 'TimeVisu',
  props: {
    time: {
      type: Object,
      required: true
    }
  },
  computed: {
    formatted () {
      return new Date(this.time.Time).toLocaleString(this.$i18n.locale)
    }
  }
}
</script>
