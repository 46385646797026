export const translateLearningStatus = (t, status) => {
  switch (status) {
    case 'initial':
      return t('recordsNotStarted')
    case 'in progress':
      return t('recordsInProgress')
    default:
      return 'unknown'
  }
}
