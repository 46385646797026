import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import VueToast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

// https://github.com/miaolz123/vue-markdown/issues/18#issuecomment-690282934
import VueMarkdown from 'vue-markdown/src/VueMarkdown'

import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'

import VueApexCharts from 'vue-apexcharts'

import PortalVue from 'portal-vue'

import VueDialog from 'vuejs-dialog'
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import './vue-dialog-custom.css'

import { createProvider } from './vue-apollo'
import { createI18n } from './vue-i18n'

import GetDatoLocales from '@/graphql/GetDatoLocales.gql'

Vue.config.productionTip = false

// get all possible languages for a learning
// and store them in store
async function setDatoLocales (client, store) {
  const resp = await client.query({ query: GetDatoLocales })
  const values = resp.data.datoLocales.enumValues
  // normalize values because
  // datocms sets "nl_BE" instead of "nl-BE"
  // https://en.wikipedia.org/wiki/IETF_language_tag
  const ll = values.map(type => type.name.replace('_', '-').toLowerCase())
  store.commit('setDatoLocales', ll)
}

const mount = async () => {
  // Load plugins
  const apolloProvider = createProvider()
  const i18n = await createI18n(apolloProvider)

  Vue.use(Donut)
  Vue.use(VueApexCharts)
  Vue.use(PortalVue)
  Vue.use(VueToast, {
    timeout: 5000
  })
  Vue.use(VueDialog)
  Vue.component('VueMarkdown', VueMarkdown)

  // No need to await result
  setDatoLocales(apolloProvider.defaultClient, store)

  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      Vue,
      dsn: 'https://3151dc61f5e54670bf5ee7bb63d9c314@o1121833.ingest.sentry.io/6158867',
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['records.oeilpouroeil-learning.com', /^\//]
        })
      ],
      logErrors: true,
      trackComponents: true,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0
    })
  }

  // Mount
  new Vue({
    router,
    store,
    i18n,
    apolloProvider,
    render: h => h(App)
  }).$mount('#app')
}

mount()
