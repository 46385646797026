<template>
  <select
    v-model="lang"
    name="language"
    required
  >
    <option
      selected
      disabled
      value=""
    >
      {{ $t('language') }}
    </option>
    <option
      v-for="l of languages"
      :key="l.code"
      :value="l.code"
    >
      {{ l.name }} ({{ l.code }})
    </option>
  </select>
</template>

<script>
import { mapState } from 'vuex'
import langs from 'langs'

export default {
  name: 'LanguageInput',
  props: {
    value: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data () {
    return {
      lang: ''
    }
  },
  computed: {
    languages () {
      // converts 'fr' to 'French'
      // An alternative solution would be to use language-tags to handle subtags
      // e.g. 'nl-BE' or 'nl-NL'
      // however, `language-tags` is heavier
      // import languageTags from 'language-tags'
      // const toName = (c) => languageTags('nl-be').language().descriptions().join(' ')
      const toName = (c) => langs.where('1', c.split('-')[0]).name
      return this.datoLocales.map(l => ({ code: l, name: toName(l) }))
    },
    ...mapState([
      'datoLocales'
    ])
  },
  watch: {
    lang () {
      this.$emit('input', this.lang)
    },
    value () {
      this.lang = this.value
    }
  },
  created () {
    if (this.value !== undefined) {
      this.lang = this.value
    }
  }
}
</script>
