<template>
  <Loading v-if="apiLoading || !defaultEmail" />
  <div v-else class="container">
    <slot name="intro" />
    <p>{{ $t('introInviteLearningTemplate') }}</p>
    <EmailForm
      v-model="email"
      :default-email="defaultEmail"
      :locales="locales"
      :update-template="updateCustomTemplate"
      :delete-template="deleteCustomTemplate"
    />
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import GetDefaultInviteLearningEmail from '@/graphql/GetDefaultInviteLearningEmail.gql'
import EmailForm from '@/components/emails/EmailForm'
import { mapState } from 'vuex'
import { API } from '@/API'

import * as Sentry from '@sentry/vue'

export default {
  name: 'EmailFormInvite',
  components: {
    Loading,
    EmailForm
  },
  props: {
    pack: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      apiLoading: true
    }
  },
  apollo: {
    defaultEmail: {
      query: GetDefaultInviteLearningEmail,
      skip () {
        return this.apiLoading
      },
      variables () {
        return {
          themeId: this.pack.datoThemeId
        }
      }
    }
  },
  computed: {
    ...mapState([
      'datoLocales'
    ]),
    locales () {
      return this.client.languages.length === 0
        ? this.datoLocales
        : this.client.languages
    }
  },
  async mounted () {
    document.body.classList.remove('locked')
    this.$emit('close')
    return API.getInviteLearningEmail(this.pack.uuid)
      .then(email => { this.email = email })
      .catch(() => { this.email = null })
      .finally(() => { this.apiLoading = false })
  },
  methods: {
    updateCustomTemplate (body) {
      return API.updateInviteLearningEmail(this.pack.uuid, body).catch((err) => {
        Sentry.captureException(err, { tag: 'apiResponse', route: 'update-invite-email' })
        throw err
      })
    },
    deleteCustomTemplate () {
      return API.deleteInviteLearningEmail(this.pack.uuid).catch((err) => {
        Sentry.captureException(err, { tag: 'apiResponse', route: 'delete-invite-email' })
        throw err
      })
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}
</style>
