<template>
  <div class="client">
    <router-link
      :to="{ name: 'admin-pack-list', params: { clientId: client.uuid } }"
    >
      <div
        class="logo-container"
        :style="{ backgroundImage: `url('${client.logoURL}')` }"
      />
      <div class="name">
        {{ client.name }}
      </div>
    </router-link>
    <button
      class="button red delete-btn"
      :disabled="loadingDelete"
      @click="deleteClient"
    >
      <Loading
        v-if="loadingDelete"
        class="loading"
      />
      <img
        v-else
        class="trash-icon"
        src="../../assets/trash.svg"
      >
    </button>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import { API } from '@/API'

export default {
  name: 'Client',
  components: {
    Loading
  },
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loadingDelete: false
    }
  },
  methods: {
    async deleteClient () {
      try {
        await this.$dialog.confirm(this.$t('confirmClientDeletion'), {
          type: 'hard',
          verification: this.client.name,
          verificationHelp: this.$t('confirmHelp'),
          okText: this.$t('confirmOk'),
          cancelText: this.$t('confirmCancel')
        })
      } catch (e) {
        return
      }
      this.loadingDelete = true
      try {
        await API.deleteClient(this.client.uuid)
        this.$emit('deleted', this.client)
      } catch (e) {
        console.error('Unable to delete client', e)
      } finally {
        this.loadingDelete = false
      }
    }
  }
}
</script>

<style scoped>
.client {
  position: relative;

  outline: 1px solid #cecece; /* use instead of border */
  margin-top: 1px;
  margin-left: 1px;

  transition: transform 0.1s;

  &:hover {
    transform: scale(1.1);
    z-index: 99;
  }
  &:focus {
    outline: none;
    transform: scale(1.1);
    z-index: 99;
    outline: 1px solid #cecece; /* use instead of border */
  }
}

a {
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  padding: 20px;
  box-sizing: border-box;
}

.delete-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  font-size: 16px;
  padding: 5px 7px;
}

.trash-icon {
  display: block;
  width: 20px;
  height: 20px;
}

.loading {
  width: 20px;
  height: 20px;

  & ::v-deep .lds-spinner {
    transform: scale(0.30) translateX(-75px) translateY(-75px);

    & div:after {
      background: white;
    }
  }
}

.name {
  margin-left: 15px;
  color: #3f3f3f;
}

.logo-container {
  background: #cecece;
  width: 65px;
  height: 65px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-position: center;
  background-size: 110%;
  background-repeat: no-repeat;
}
</style>
