<template>
  <form
    @submit.prevent="submitCaller"
  >
    <div class="inputs">
      <input
        v-model="form.user"
        type="text"
        :placeholder="admin ? $t('adminLoginUserPlaceholder') : $t('loginUserPlaceholder')"
      >
      <input
        v-model="form.password"
        type="password"
        :placeholder="admin ? $t('adminLoginPasswordPlaceholder') : $t('loginPasswordPlaceholder')"
      >
    </div>
    <div class="submit">
      <input
        :disabled="processing"
        class="button rounded black"
        type="submit"
        :value="$t('loginSubmitButton')"
      >
    </div>
  </form>
</template>

<script>
export default {
  name: 'LoginForm',
  props: {
    submit: {
      type: Function,
      required: true
    },
    admin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      processing: false,
      form: {
        user: '',
        password: ''
      }
    }
  },
  methods: {
    async submitCaller () {
      this.processing = true
      const { user, password } = this.form
      await this.submit(user, password)
      this.processing = false
    }
  }
}
</script>

<style scoped>
.inputs {
  margin-bottom: 40px;

  & > * {
    width: 300px;
  }
}

.submit {
  margin-bottom: 20px;
}
</style>
