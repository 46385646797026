<template>
  <div class="container">
    <input
      :value="value"
      type="text"
      :placeholder="$t('adminClientsSearchPlaceholder')"
      @input="$emit('input', $event.target.value)"
      @keydown="keydown"
    >
  </div>
</template>

<script>
export default {
  name: 'ClientSearch',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  methods: {
    keydown (ev) {
      // Escape
      if (ev.keyCode === 27) {
        this.$emit('input', '')
      }
    }
  }
}
</script>

<style scoped>
input {
  &:placeholder-shown {
    background-image: url('../../assets/icon-search.svg');
    background-position: calc(100% - 10px);
    background-size: auto 45%;
    background-repeat: no-repeat;
  }
}
</style>
