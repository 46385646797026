<template>
  <Loading v-if="apiLoading || !defaultEmail" />
  <div v-else class="container">
    <slot name="intro" />
    <p>{{ $t('introInviteLearningTemplate') }}</p>
    <EmailSend
      v-model="email"
      class="content"
      :default-email="defaultEmail"
      :locales="locales"
      :send-email="inviteUsers"
      settings-router-name="pack-settings-invite"
    />
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import GetDefaultInviteLearningEmail from '@/graphql/GetDefaultInviteLearningEmail.gql'
import EmailSend from '@/components/emails/EmailSend'
import { mapState } from 'vuex'
import { API } from '@/API'

import * as Sentry from '@sentry/vue'

export default {
  name: 'EmailSendInvite',
  components: {
    Loading,
    EmailSend
  },
  props: {
    pack: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      apiLoading: true
    }
  },
  apollo: {
    defaultEmail: {
      query: GetDefaultInviteLearningEmail,
      skip () {
        return this.apiLoading
      },
      variables () {
        return {
          themeId: this.pack.datoThemeId
        }
      }
    }
  },
  computed: {
    ...mapState([
      'datoLocales'
    ]),
    locales () {
      return this.client.languages.length === 0
        ? this.datoLocales
        : this.client.languages
    }
  },
  async mounted () {
    return API.getInviteLearningEmail(this.pack.uuid)
      .then(email => { this.email = email })
      .catch(() => { this.email = null })
      .finally(() => { this.apiLoading = false })
  },
  methods: {
    async inviteUsers () {
      return API.inviteCurrentUsers(this.pack.uuid, false)
        .then(users => this.$toast.success(
        `${users.length} ` + users.length > 1
          ? this.$t('usersInvited')
          : this.$t('userInvited')
        ))
        .catch(err => {
          Sentry.captureException(err, { tag: 'apiResponse', route: 'invite-current-user-email' })
          this.$toast.error(`Error: ${err}`)
        })
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}
.content {
    margin-top: 40px;
  }
</style>
