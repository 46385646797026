<template>
  <form v-if="form" class="email-form" @submit.prevent="submit">
    <div v-if="hasName" class="field">
      <label :for="`${_uid}-name`" class="bold">
        {{ $t('emailName') }}
      </label>
      <input
        :id="`${_uid}-name`"
        v-model="form.name"
        name="name"
        type="text"
        maxlength="50"
        required
      >
      <p class="help">{{ $t('emailNameHelp') }}</p>
    </div>
    <div class="field">
      <label :for="`${_uid}-subject`" class="bold">
        {{ $t('emailSubject') }}
      </label>
      <input
        :id="`${_uid}-subject`"
        v-model="form.subject[selectedLocale]"
        name="subject"
        type="text"
      >
      <p class="help">{{ $t('emailSubjectHelp') }}</p>
    </div>
    <div class="field">
      <label class="bold">{{ $t('emailContent') }}</label>
      <EasyMDE v-model="form.content[selectedLocale]" name="content" />
      <p class="help">{{ $t('emailContentHelp') }}</p>
    </div>
    <div class="field">
      <label :for="`${_uid}-custom-sender`" class="bold">
        {{ $t('emailSenderName') }}
      </label>
      <input
        :id="`${_uid}-custom-sender`"
        v-model="form.customSenderName[selectedLocale]"
        name="custom-sender-name"
        type="text"
      >
      <p class="help">{{ $t('emailSenderNameHelp') }}</p>
    </div>
    <div class="field inline-field-container">
      <label :for="`${_uid}-show-header`" class="bold">
        {{ $t('emailShowHeader') }}
      </label>
      <input
        :id="`${_uid}-show-header`"
        v-model="form.showHeader"
        name="showHeader"
        type="checkbox"
      >
    </div>
    <!-- Following fields are only available for admins -->
    <fieldset v-if="$store.getters.isAdmin">
      <legend>{{ $t('adminFields') }}</legend>
      <div class="field inline-field-container">
        <label :for="`${_uid}-images-as-attachment`" class="bold">
          {{ $t('emailImagesAsAttachment') }}
        </label>
        <input
          :id="`${_uid}-images-as-attachment`"
          v-model="form.imagesAsAttachment"
          type="checkbox"
          name="imagesAsAttachment"
        >
      </div>
      <div class="field inline-field-container">
        <label :for="`${_uid}-custom-template`" class="bold">
          {{ $t('customTemplateName') }}
        </label>
        <select
          :id="`${_uid}-custom-template`"
          v-model="form.customTemplate"
          name="customTemplate"
        >
          <option v-for="t of allCustomTemplates" :key="t" :value="t">{{ t }}</option>
        </select>
      </div>
    </fieldset>
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <div class="button-wrapper">
      <template v-if="!loading">
        <button class="button btn-m green">
          {{ $t('save') }}
        </button>
        <template v-if="sendEmail">
          <template v-if="readyToSend">
            <div class="button btn-m green" @click="sendEmail">
              {{ $t('send') }}
            </div>
          </template>
          <Loading v-else />
        </template>
      </template>
      <template v-else>
        <Loading />
      </template>
    </div>
  </form>
</template>

<script>
import Loading from '@/components/Loading'
import EasyMDE from '@/components/EasyMDE'
import { API } from '@/API'

export default {
  name: 'CustomEmailForm',
  components: {
    EasyMDE,
    Loading
  },
  props: {
    locales: {
      type: Array,
      required: true
    },
    readyToSend: {
      type: Boolean,
      required: false,
      default: true
    },
    selectedLocale: {
      type: String,
      required: true
    },
    email: {
      type: Object,
      required: false,
      default: null
    },
    defaultEmail: {
      type: Object,
      required: false,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: String,
      required: false,
      default: null
    },
    hasName: {
      type: Boolean,
      required: false,
      default: false
    },
    sendEmail: {
      type: Function,
      required: false,
      default: null
    }
  },
  data () {
    return {
      allCustomTemplates: [],
      form: null
    }
  },
  computed: {
    defaultHeaderColor () {
      return '#FFFFFF'
    },
    defaultSenderName () {
      return 'Oeil pour Oeil'
    },
    defaultCustomTemplate () {
      if (this.$store.getters.isAdmin) {
        return this.allCustomTemplates[0]
      }
      return undefined
    }
  },
  async created () {
    if (this.$store.getters.isAdmin) {
      this.allCustomTemplates = await API.getCustomTemplates()
    }
    this.form = this.newForm()
  },
  methods: {
    // convert i18n array (dato type) to i18n map (api type)
    I18nArrToMap (arr) {
      const map = {}
      for (const { locale, value } of arr) {
        const normalizedLocale = locale.replaceAll('_', '-').toLowerCase()
        map[normalizedLocale] = value
      }
      return map
    },
    // add empty values on i18n fields for Vue reactivity
    newI18nText (init = {}, def = '') {
      const t = {}
      this.locales.forEach(l => {
        t[l] = init[l] ?? def
      })
      return t
    },
    // removes empty value for an i18n field
    filterI18nText (obj) {
      return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value.trim() !== ''))
    },
    newForm () {
      // no template is currently set
      if (this.email === null) {
        const ret = {
          content: this.newI18nText(),
          subject: this.newI18nText(),
          customHeaderColor: '#FFFFFF',
          customSenderName: this.newI18nText({}, this.defaultSenderName),
          showHeader: true,
          imagesAsAttachment: null,
          customTemplate: this.defaultCustomTemplate
        }
        // use default subject / template when client customize its template
        if (this.defaultEmail) {
          ret.subject = this.I18nArrToMap(this.defaultEmail.subject)
          ret.content = this.I18nArrToMap(this.defaultEmail.content)
        }
        if (this.hasName) {
          ret.name = ''
        }
        return ret
      }

      const form = { ...this.email }

      form.content = this.newI18nText(form.content)
      form.subject = this.newI18nText(form.subject)
      form.customSenderName = this.newI18nText(form.customSenderName || {}, this.defaultSenderName)
      if (form.customHeaderColor === undefined || form.customHeaderColor === null) {
        form.customHeaderColor = this.defaultHeaderColor
      }

      return form
    },
    submit () {
      const { form } = this

      // keep default value on API
      const headerColor = () => {
        if (form.customHeaderColor === this.defaultHeaderColor) {
          return undefined
        }
        return form.customHeaderColor
      }

      const customSenderName = () => {
        const val = { ...form.customSenderName }
        for (const lang in val) {
          if (val[lang] === this.defaultSenderName) {
            delete val[lang]
          }
        }
        if (Object.keys(val).length === 0) {
          return null
        }
        return val
      }

      const body = {
        // remove unset locales
        subject: this.filterI18nText(form.subject),
        content: this.filterI18nText(form.content),
        showHeader: form.showHeader,
        customSenderName: customSenderName(),
        customHeaderColor: headerColor()
      }
      if (this.hasName) {
        body.name = form.name
      }
      if (this.$store.getters.isAdmin) {
        body.imagesAsAttachment = form.imagesAsAttachment
        body.customTemplate = form.customTemplate
      }

      this.$emit('submit', body)
    }
  }
}
</script>

<style scoped>
.inline-field-container {
  display: flex;

  & :first-child {
    width: 100%;
  }
}

.email-form {
  & input {
    box-sizing: border-box;
  }

  & input[type="text"] {
    width: 100%;
  }

  & input[type="color"] {
    display: inline-block;
  }
}

.error {
  color: red;
}

.field {
  margin-bottom: 20px;
}

.help {
  font-size: 14px;
  margin: 0;
  color: grey;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;

}

.button:disabled {
    background-color: grey;
  }
</style>
