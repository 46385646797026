<template>
  <portal to="modal">
    <div class="modal-container" :class="{ open }" @click.self="close">
      <div class="modal">
        <div class="header">
          <h3 v-if="title" class="title">{{ title }}</h3>
          <button class="close-btn" @click="close">&times;</button>
        </div>
        <slot />
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    open: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    open () {
      if (this.open) {
        document.body.classList.add('locked')
      }
    }
  },
  methods: {
    close () {
      document.body.classList.remove('locked')
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.modal-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 300ms;
  cursor: pointer;
}

.modal-container:not(.open) {
  pointer-events: none;
  opacity: 0;
}

.modal {
  position: relative;
  max-width: 60%;
  min-width: 800px;
  max-height: 85%;
  overflow: scroll;
  cursor: initial;
  background: white;
  margin-top: 50px;
  padding: 20px 30px;
}

.header {
  display: flex;
  justify-content: center;
}

.title {
  margin: 10px auto 40px auto;
}

.close-btn {
  position: absolute;
  right: 30px;
  background: none;
  border: none;
  cursor: pointer;
}
</style>
