<template>
  <div>
    <Header />
    <h2 class="page-title">
      {{ $t('resetPassword') }}
    </h2>
    <div v-if="checkTokenLoading || !checkTokenSuccess">
      <Loading v-if="checkTokenLoading" />
      <div v-else-if="checkTokenError">
        <div v-if="checkTokenError === 'token-not-found'">
          {{ $t('resetPasswordExpired') }}
          <div>
            <router-link
              class="button yellow"
              :to="{ name: 'forgot-password' }"
            >
              {{ $t('forgotPassword') }}
            </router-link>
          </div>
        </div>
        <div v-else>
          {{ checkTokenError }}
        </div>
      </div>
    </div>
    <div v-else>
      <form
        v-if="!success"
        @submit.prevent="resetPassword"
      >
        <PasswordInput
          v-model="password"
          @valid="validPassword = $event"
        />
        <button
          class="button yellow"
          :disabled="!password || !validPassword || loading"
          type="submit"
        >
          {{ loading ? $t('loading') : $t('submit') }}
        </button>
        <div
          v-if="error"
          class="error"
        >
          {{ error }}
        </div>
      </form>
      <div v-else>
        {{ $t('resetPasswordSuccess') }}
        <div>
          <router-link
            class="button yellow"
            :to="{ name: 'login' }"
          >
            {{ $t('login') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import PasswordInput from '@/components/PasswordInput'
import Loading from '@/components/Loading'
import { API, formatAPIErr } from '@/API'

export default {
  name: 'ResetPassword',
  components: {
    Header,
    Loading,
    PasswordInput
  },
  data () {
    return {
      password: '',
      validPassword: false,

      loading: false,
      success: false,
      error: '',

      checkTokenLoading: true,
      checkTokenSuccess: false,
      checkTokenError: ''
    }
  },
  computed: {
    token () {
      return this.$route.query.token
    },
    accountUuid () {
      return this.$route.query.account
    }
  },
  mounted () {
    this.checkToken()
  },
  methods: {
    async checkToken () {
      this.checkTokenLoading = true
      this.checkTokenSuccess = false
      this.checkTokenError = ''
      try {
        await API.resetPasswordCheckToken(this.accountUuid, this.token)
        this.checkTokenSuccess = true
      } catch (e) {
        if (e.response && e.response.data && e.response.data.error && e.response.data.error === 'token not found') {
          this.checkTokenError = 'token-not-found'
        } else {
          this.checkTokenError = formatAPIErr(e)
        }
      } finally {
        this.checkTokenLoading = false
      }
    },
    async resetPassword () {
      this.loading = true
      this.success = false
      this.error = ''
      try {
        await API.resetPassword(this.accountUuid, this.token, this.password)
        this.success = true
      } catch (e) {
        this.error = formatAPIErr(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.error {
  color: red;
}

.button {
  margin: 20px;
}
</style>
