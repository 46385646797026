<template>
  <div class="container">
    <!-- Mode selection -->
    <div
      v-if="!success"
      class="mode-selection"
    >
      <button
        :class="[ 'action', { active: mode === 'individual' } ]"
        @click="mode = 'individual'"
      >
        {{ $t('individualMode') }}
      </button>
      <!--<span class="separator">|</span>-->
      <button
        :class="[ 'action', { active: mode === 'bulk' } ]"
        @click="mode = 'bulk'"
      >
        {{ $t('bulkMode') }}
      </button>
    </div>
    <div :class="[ 'content', 'yellow-bg', { hide: success } ]">
      <p>{{ $t('importInstructions') }}</p>
      <!-- Forms -->
      <Individual
        v-if="mode === 'individual'"
        :is-processing="isProcessing"
        :multi-learning="multiLearning"
        @submit="submit"
      />
      <Bulk
        v-if="mode === 'bulk'"
        :is-processing="isProcessing"
        :multi-learning="multiLearning"
        @submit="submit"
      />
      <div
        v-if="apiErrors.length > 0"
        class="error"
      >
        <p
          v-for="(err, idx) of apiErrors"
          :key="idx"
        >
          {{ err }}
        </p>
      </div>
    </div>
    <div
      :class="[ 'success', 'yellow-bg', { show: success } ]"
    >
      <img
        class="check-mark"
        src="@/assets/icon-check.svg"
        alt="&#x2713;"
      >
      <p>{{ $t('importSuccess') }}</p>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import Individual from './Individual'
import Bulk from './Bulk'

export default {
  name: 'SubscribeUsers',
  components: {
    Individual,
    Bulk
  },
  props: {
    subscribeUsers: {
      type: Function,
      required: true
    },
    multiLearning: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      isProcessing: false,
      apiErrors: [],
      mode: 'individual',
      success: false
    }
  },
  methods: {
    resetData () {
      this.apiErrors = []
      this.success = false
      this.mode = 'individual'
    },
    async submit ({ users, packs }) {
      this.isProcessing = true
      this.apiErrors = []

      this.subscribeUsers(users, packs)
        .then(apiUsers => {
          this.$emit('new-users', apiUsers)
          this.$toast.success(this.$t('importedUsers'))

          this.isProcessing = false
          this.success = true

          setTimeout(() => {
            this.$emit('success')
            document.body.classList.remove('locked')
            this.resetData()
          }, 1000)
        })
        .catch((e) => {
          Sentry.captureException(e, { tag: 'apiResponse', route: 'subscribe-user' })
          this.apiErrors.push(`API error: ${e.toString()}`)
          if (e.response && e.response.data && e.response.data.error) {
            this.apiErrors.push(e.response.data.error)
          } else if (e.response && e.response.data) {
            this.apiErrors.push(JSON.stringify(e.response.data))
          }
          this.isProcessing = false
          this.$toast.error(`Error: ${e}`)
        }
        )
    },
    close () {
      // reset data
      this.resetData()
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.users {
  max-height: 500px;
}

.error {
  color: red;
}

.yellow-bg {
  background-color: #FFC72E;
}

.container {
  text-align: center;
  /* padding: 0 20px; */

  /* For success positionning */
  position: relative;
}

.content {
  padding: 30px;

  /* For close cross absolute positioning */
  position: relative;

  &.hide {
    opacity: 0;
  }
}

.mode-selection {
  display: flex;
  justify-content: center;

  & > .action {
    width: 300px;
    text-transform: uppercase;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 10px 5px;

    background: white;
    color: black;
    margin: 0;

    &.active {
      background: #FFC72E;
    }
  }
}

.close {
  cursor: pointer;
  color: white;
  font-size: 45px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 5%;
  z-index: 1;
}

.success {
  text-align: center;

  & > * {
    margin: 10px auto;
  }

  & > .check-mark {
    width: 100px;
    display: block;
    margin-bottom: 20px;
  }

  & > p {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
  }
}

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;

  opacity: 0;
  transition: opacity 0.1s;

  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: visible;
  }
}
</style>
