<template>
  <div>
    <Header
      :bg-color="theme ? theme.courseColor.hex : null"
      :title-logo="theme ? theme.logo.url : null"
    />
    <router-link
      class="nav-btn"
      :style="{ color: theme ? theme.courseColor.hex: null }"
      :to="prevURL"
    >
      &#9664; {{ $t('navLearnings') }}
    </router-link>
    <slot />
  </div>
</template>

<script>
import GetThemeById from '@/graphql/GetThemeById.gql'
import Header from '@/components/Header'
import { API } from '@/API'

export default {
  name: 'LearningLayout',
  components: {
    Header
  },
  data () {
    return {
      theme: null,
      pack: null
    }
  },
  apollo: {
    theme: {
      query: GetThemeById,
      skip () {
        return !this.pack
      },
      variables () {
        return {
          id: this.pack.datoThemeId,
          locale: this.$i18n.locale
        }
      }
    }
  },
  computed: {
    prevURL () {
      if (this.$store.getters.isAdmin && this.pack) {
        return { name: 'admin-pack-list', params: { clientId: this.pack.clientUUID } }
      }
      return { name: 'pack-list' }
    }
  },
  watch: {
    theme () {
      if (!this.theme) {
        return
      }
      this.$emit('theme', this.theme)
    }
  },
  async mounted () {
    this.pack = await API.getPack(this.$route.params.packId)
    this.$emit('pack', this.pack)
  }
}
</script>
