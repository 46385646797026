<template>
  <div class="header-btn">
    <button
      v-if="canImport"
      class="nostyle action-container"
      @click="showCsvImport = true"
    >
      <div class="button rounded yellow">
        <img
          class="btn-img"
          src="@/assets/icon-upload-white.svg"
          alt=""
        >
      </div>
      <p class="btn-title">
        {{ $t('recordsImportTitle') }}
      </p>
      <p class="btn-text">
        {{ $t('recordsImportSubtitle') }}
      </p>
    </button>
    <ExportProgress
      :download="downloadPackProgress"
    />
    <button
      v-if="canImport"
      class="nostyle action-container"
      @click="showSendInvitation = true"
    >
      <div class="button alt-blue rounded">
        <img
          class="btn-img"
          src="@/assets/paper-plane-white.svg"
          alt=""
        >
      </div>
      <p class="btn-title">
        {{ $t('sendInvitationTitle') }}
      </p>
      <p class="btn-text">
        {{ $t('sendInvitationSubtitle') }}
      </p>
    </button>
    <router-link
      v-if="canImport"
      class="nostyle action-container"
      :to="{ name: 'pack-settings-reminder' }"
    >
      <div class="button alt-red rounded">
        <img
          class="btn-img"
          src="@/assets/rappel.svg"
          alt=""
        >
      </div>
      <p class="btn-title">
        {{ $t('configureReminderTitle') }}
      </p>
      <p class="btn-text">
        {{ $t('configureReminderSubtitle') }}
      </p>
    </router-link>
    <button
      :disabled="!progress"
      class="nostyle action-container disabled-wait"
      @click="showSendCertificate = true"
    >
      <div class="button rounded alt-green">
        <img
          class="btn-img"
          src="@/assets/attestation.svg"
          alt=""
        >
      </div>
      <p class="btn-title">
        {{ $t('sendDiplomaButtonTitle') }}
      </p>
      <p class="btn-text">
        {{ $t('sendDiplomaButtonDescription') }}
      </p>
    </button>
    <Modal
      :title="$t('titleSendInvitation')"
      :open="showSendInvitation"
      @close="showSendInvitation = false"
    >
      <EmailSendInvite
        :pack="pack"
        :client="client"
        :progress="progress"
        :users-map="usersMap"
        @success="showSendInvitation = false"
        @close="showSendInvitation = false"
      />
    </Modal>
    <Modal
      :title="$t('titleSendAttestation')"
      :open="showSendCertificate"
      @close="showSendCertificate = false"
    >
      <EmailSendCertificate
        :pack="pack"
        :client="client"
        :progress="progress"
        :users-map="usersMap"
        @success="showSendCertificate = false"
        @close="showSendCertificate = true"
      >
        <div slot="intro">
          {{ $t('introSendAttestation') }}
        </div>
      </EmailSendCertificate>
    </Modal>
    <Modal
      :title="$t('recordsImportTitle')"
      :open="showCsvImport"
      @close="showCsvImport = false"
    >
      <SubscribeUsers
        class="import-users"
        :subscribe-users="subscribeUsers"
        @new-users="addUsers"
        @success="showCsvImport = false"
        @close="showCsvImport = false"
      />
    </Modal>
  </div>
</template>

<script>
import ExportProgress from '@/components/ExportProgress'
import Modal from '@/components/Modal'
import SubscribeUsers from '@/components/subscribe-users/SubscribeUsers'
import EmailSendInvite from '@/components/emails/EmailSendInvite'
import EmailSendCertificate from '@/components/emails/EmailSendCertificate'

import { API } from '@/API'

export default {
  name: 'PackActions',
  components: {
    ExportProgress,
    Modal,
    EmailSendCertificate,
    EmailSendInvite,
    SubscribeUsers
  },
  props: {
    pack: {
      type: Object,
      required: false,
      default: null
    },
    client: {
      type: Object,
      required: true
    },
    progress: {
      type: Array,
      required: false,
      default: null
    },
    usersMap: {
      type: Object,
      required: false,
      default: null
    },
    addUsers: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      showSendInvitation: false,
      showSendCertificate: false,
      showCsvImport: false
    }
  },
  computed: {
    canImport () {
      return this.pack
    }
  },
  methods: {
    async downloadPackProgress (fmt) {
      return API.downloadPackProgress(this.pack.uuid, fmt)
    },
    async subscribeUsers (users) {
      return API.subscribeUsers(this.pack.uuid, users)
    }
  }
}

</script>

<style scoped>
.header-btn {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 20px;

  & > * {
    margin: 10px 33px;
  }

}
a {
  text-decoration: none;
  text-align: center;
  color: rgb(44, 62, 80);
}

</style>
