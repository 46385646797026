import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logged: false,
    role: '',
    clientUUID: '',
    clientSearch: '',
    account: {},
    // all available languages for learnings
    datoLocales: []
  },
  mutations: {
    setLogged (state) {
      state.logged = true
    },
    logout (state) {
      state.logged = false
      state.role = ''
      state.clientUUUID = ''
    },
    setRole (state, role) {
      state.role = role
    },
    setClientSearch (state, value) {
      state.clientSearch = value
    },
    setClientUUID (state, clientUUID) {
      state.clientUUID = clientUUID
    },
    setAccount (state, account) {
      state.account = account
    },
    setDatoLocales (state, ll) {
      state.datoLocales = ll
    }
  },
  getters: {
    isAdmin (state) {
      return state.role === 'admin'
    },
    isAccess (state) {
      return state.role === 'access'
    }
  }
})
