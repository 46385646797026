<template>
  <span v-if="translated">
    {{ translated }}
  </span>
  <span v-else>
    ⚠️ Missing translation
  </span>
</template>

<script>
export default {
  name: 'DatoI18nText',
  props: {
    val: {
      type: Array,
      required: true
    },
    locale: {
      type: String,
      required: true
    }
  },
  computed: {
    translated () {
      return this.datoT(this.val, this.locale)
    }
  },
  methods: {
    datoT (field, locale) {
      return field.find(f => f.locale.replace('_', '-').toLowerCase() === locale.toLowerCase()).value
    }
  }
}
</script>
