<template>
  <div>
    <Header class="header" />
    <div class="error">
      {{ $t('notFound') }}
    </div>
    <router-link
      to="/"
      class="button rounded yellow"
    >
      {{ $t('goToHomepage') }}
    </router-link>
  </div>
</template>

<script>
import Header from '@/components/Header'

export default {
  components: {
    Header
  }
}
</script>

<style scoped>
.error {
  color: red;
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}

.header {
  margin-bottom : 20px;
}
</style>
