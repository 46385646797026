<template>
  <div class="nav" :style="{ '--active-color': packColor }">
    <router-link
      class="item"
      :to="{ name: 'pack-detail', params: { packId: $route.params.packId } }"
    >
      {{ $t('recordsTitle') }}
    </router-link>
    <div class="separator">|</div>
    <router-link
      class="item"
      :class="{'is-active': $route.name.startsWith('pack-settings') }"
      :to="{ name: 'pack-settings-invite', params: { packId: $route.params.packId } }"
    >
      {{ $t('settingsTitle') }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'LearningMenu',
  props: {
    theme: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    packColor () {
      return this.theme ? this.theme.courseColor.hex : '#FFC72E'
    }
  }
}
</script>

<style scoped>
.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 20px;

  & > * {
    margin: 10px;
    font-size: 45px;
    font-weight: 200;
  }
}

a {
  color: #cccccc;
  text-decoration: none;

  &.router-link-exact-active, &.is-active {
    color: var(--active-color);
  }
}

.separator {
  color: #cccccc;
}
</style>
