<template>
  <a
    class="button yellow action-btn"
    :href="url"
    target="_blank"
    :title="$t('login')"
  >
    <img
      class="action-icon"
      src="@/assets/voir-session.svg"
    >
  </a>
</template>

<script>
export default {
  name: 'LearnerLogin',
  props: {
    loginMode: {
      type: String,
      required: true
    },
    learningUrl: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    url () {
      let params = {}
      if (this.loginMode === 'token') {
        params = { login_token: this.user.loginToken }
      } else {
        params = { email: this.user.email }
      }
      const query = new URLSearchParams(params)
      return `${this.learningUrl}?${query.toString()}`
    }
  }
}
</script>
