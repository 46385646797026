<template>
  <div>
    <table
      v-if="records.length"
      class="record"
    >
      <tr>
        <th>{{ $t('recordsUsers') }}</th>
        <th>{{ getEmail(selectedRecord) }}</th>
      </tr>
      <tr>
        <td class="user-list">
          <div class="user-list-container">
            <div
              v-for="record of records"
              :key="record.userUUID"
              :class="[ 'user', { active: selectedRecord != null && record.userUUID === selectedRecord.userUUID } ]"
              @click="select(record.userUUID)"
            >
              {{ getEmail(record) }}
            </div>
          </div>
        </td>
        <td>
          <table
            v-if="selectedRecord"
            class="details"
          >
            <tr>
              <td>{{ $t('recordsStatus') }}</td>
              <td v-if="selectedRecord.progress === 100">
                {{ $t('recordsDone') }}<img
                  class="picto"
                  src="@/assets/complete.svg"
                >
              </td>
              <td v-else>
                {{ $t('recordsInProgress') }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('recordsProgression') }}</td>
              <td>{{ selectedRecord.progress }}%</td>
            </tr>
            <tr>
              <td>{{ $t('recordsScore') }}</td>
              <td>{{ selectedRecord.score }}%</td>
            </tr>
            <tr>
              <td>{{ $t('recordsTime') }}</td>
              <td><ElapsedTime :time="selectedRecord.elapsedTime" /></td>
            </tr>
            <tr>
              <td>{{ $t('recordsSuccess') }}</td>
              <td v-if="selectedRecord.success === true">
                <img
                  class="picto-big"
                  src="@/assets/valid.svg"
                >
              </td>
              <td v-else>
                <img
                  class="picto-big"
                  src="@/assets/fail.svg"
                >
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <div v-else>
      {{ $t('errorNoUsers') }}
    </div>
  </div>
</template>

<script>
import ElapsedTime from '@/components/data/ElapsedTime.vue'

export default {
  name: 'ModuleUsers',
  components: {
    ElapsedTime
  },
  props: {
    records: {
      type: Array,
      required: false,
      default: null
    },
    usersMap: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      selectedRecord: null
    }
  },
  methods: {
    select (userUUID) {
      this.selectedRecord = this.records.find((r) => userUUID === r.userUUID)
    },
    getEmail (r) {
      if (!this.usersMap || !r || !this.usersMap[r.userUUID]) {
        return ''
      }
      return this.usersMap[r.userUUID].email
    }
  }
}
</script>

<style scoped>
.record {
  width: 100%;
  border-spacing: 0;
}
.user-list {
  vertical-align: top;
  padding: 0;
}
.user-list-container {
  max-height: 250px;
  overflow-y: auto;
}
.user {
  cursor: pointer;
  padding: 7px 20px;
}
.user.active {
  background-color: #ffc72e;
}
.record > tr > td, .record > tr > th {
  border: 1px solid grey;
}
.record th {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 20px 5px;
}
.record > tr > td:nth-child(1) {
  width: 50%;
}
.record > tr > td:nth-child(2) {
  width: 50%;
}
.details {
  width: 100%;
  padding: 20px;
}
.details td {
  padding-bottom: 10px;
}
.details td:nth-child(1) {
  min-width: 40%;
}
.details td:nth-child(2) {
  min-width: 60%;
}
.picto {
  width: 15px;
  margin-left: 10px;
}
.picto-big {
  width: 25px;
}
</style>
