<template>
  <div>
    <div class="header">
      <input
        v-model="search"
        type="text"
        :placeholder="$t('searchUsersPlaceholder')"
      >
      <select v-model="filterStatus">
        <option value="">
          -- {{ $t('filterStatusPlaceholder') }} --
        </option>
        <option value="initial">
          {{ $t('recordsNotStarted') }}
        </option>
        <option value="in progress">
          {{ $t('recordsInProgress') }}
        </option>
        <option value="completed">
          {{ $t('recordsDone') }}
        </option>
      </select>
    </div>
    <table class="list">
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              name="selectAll"
              class="select-all"
              :title="$t('selectAll')"
              :checked="filteredUsers.every(u => value[u.uuid] === true)"
              @change="selectAll($event.target.checked)"
            >
          </th>
          <th>{{ $t('email') }}</th>
          <th>{{ $t('recordsStatus') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="u of visibleUsers"
          :key="u.uuid"
          class="line"
          @click="setVal(u.uuid, !(value[u.uuid] || false))"
        >
          <td>
            <input
              type="checkbox"
              class="select"
              :checked="value[u.uuid] || false"
            >
          </td>
          <td>{{ u.email }}</td>
          <td><RecordStatus :status="progressByUser[u.uuid].status" /></td>
        </tr>
      </tbody>
    </table>
    <div class="search-count">{{ filteredUsers.length }} {{ $t('usersMatchYourSearch') }}</div>
    <Pagination v-model="currentPage" :page-count="pageCount" />
    <div class="footer">
      {{ Object.keys(value).length }} {{ $t('selectedUsers') }}
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import RecordStatus from '@/components/data/RecordStatus'

export default {
  name: 'SelectUsers',
  components: {
    Pagination,
    RecordStatus
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    progress: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      currentPage: 1,
      filterStatus: '',
      search: ''
    }
  },
  computed: {
    progressByUser () {
      const map = {}
      for (const p of this.progress) {
        map[p.userUUID] = p
      }
      return map
    },
    filteredUsers () {
      const userMatchSearch = (user) => {
        if (!this.search) {
          return true
        }
        const fields = [
          user.email,
          user.firstName,
          user.lastName
        ]
        // Remove special characters to improve search
        const clean = input => input.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        return fields.some(f => clean(f).includes(clean(this.search)))
      }
      const userMatchStatus = (user) => {
        if (!this.filterStatus) {
          return true
        }
        return this.progressByUser[user.uuid].status === this.filterStatus
      }
      return this.users.filter((u) => userMatchSearch(u) && userMatchStatus(u))
    },
    visibleUsers () {
      const offset = (this.currentPage - 1) * this.pageLength
      return this.filteredUsers.slice(offset, offset + this.pageLength)
    },
    pageLength () {
      return 10
    },
    pageCount () {
      return Math.ceil(this.filteredUsers.length / this.pageLength)
    }
  },
  methods: {
    copyVal () {
      const res = {}
      for (const uid in this.value) {
        if (this.value[uid] === true) {
          res[uid] = true
        }
      }
      return res
    },
    setVal (userId, val) {
      const res = this.copyVal()
      if (val === true) {
        res[userId] = true
      } else {
        delete res[userId]
      }
      this.$emit('input', res)
    },
    selectAll (isChecked) {
      const res = this.copyVal()
      for (const u of this.filteredUsers) {
        if (isChecked) {
          res[u.uuid] = true
        } else {
          delete res[u.uuid]
        }
      }
      this.$emit('input', res)
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
}

.list {
  width: 70vw;
}

.line {
  cursor: pointer;
}

.select, .select-all {
  cursor: pointer;
}

.search-count {
  font-size: 14px;
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
