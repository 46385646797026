<template>
  <tr>
    <td>{{ account.email }}</td>
    <td><Time :time="account.lastConnection" /></td>
    <td>{{ account.clientName }}</td>
    <td>{{ account.role }}</td>
    <td class="center">
      {{ account.lang }}
    </td>
    <td class="center">
      <template v-if="account.active">
        <img
          class="picto-img"
          src="@/assets/complete.svg"
          :title="$t('accountStatusActive')"
        >
      </template>
      <template v-else-if="account.invitationExpired === false">
        <img
          class="picto-img"
          src="@/assets/paper-plane.svg"
          :title="$t('accountStatusInvitationSent')"
        >
      </template>
      <template v-else-if="account.invitationExpired === true">
        <img
          class="picto-img"
          src="@/assets/fail.svg"
          :title="$t('accountStatusInvitationExpired')"
        >
      </template>
      <template v-else>
        Unexpected user: no invitation but account is not active
      </template>
    </td>
    <td class="actions">
      <button
        class="button red"
        :disabled="deleteLoading"
        :title="$t('delete')"
        @click="deleteAccount"
      >
        <Loading
          v-if="deleteLoading"
          class="small-loading loading-white"
        />
        <img
          v-else
          class="action-icon"
          src="@/assets/trash.svg"
        >
      </button>
      <button
        v-if="account.invitationExpired"
        class="button yellow"
        :title="$t('resendInvite')"
        :disabled="resendInviteLoading"
        @click="resendInvite"
      >
        <Loading
          v-if="resendInviteLoading"
          class="small-loading loading-white"
        />
        <img
          v-else
          class="action-icon"
          src="@/assets/reply.svg"
        >
      </button>
    </td>
  </tr>
</template>

<script>
import Loading from '@/components/Loading'
import Time from '@/components/data/Time.vue'
import { API } from '@/API'

export default {
  name: 'Account',
  components: {
    Time,
    Loading
  },
  props: {
    account: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      deleteLoading: false,
      resendInviteLoading: false
    }
  },
  methods: {
    async deleteAccount () {
      try {
        await this.$dialog.confirm(this.$t('confirmAccessDeletion'), {
          type: 'hard',
          verification: this.account.email,
          verificationHelp: this.$t('confirmHelp'),
          okText: this.$t('confirmOk'),
          cancelText: this.$t('confirmCancel')
        })
      } catch (e) {
        return
      }
      this.deleteLoading = true
      try {
        await API.deleteAccount(this.account.uuid)
        this.$emit('deleted', this.account)
      } catch (e) {
        console.error('Unable to delete account', e)
      } finally {
        this.deleteLoading = false
      }
    },
    async resendInvite () {
      this.resendInviteLoading = true
      try {
        await API.inviteAccountReInvite(this.account.uuid)
        this.$emit('reinvited', this.account)
        alert(this.$t('resendInviteSuccess'))
      } catch (e) {
        console.error('Unable to re-invite account', e)
      } finally {
        this.resendInviteLoading = false
      }
    }
  }
}
</script>

<style scoped>
td {
  font-size: 18px;
  padding: 5px 10px;
}

.picto-img {
  width: 30px;
}

.center {
  text-align: center;
}

.actions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  & > * {
    margin: 0;
    width: 50%;
    min-width: 70px;
  }
}
</style>
