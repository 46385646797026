<template>
  <form @submit.prevent="submit">
    <input v-model="label" type="text" :placeholder="$t('label')" name="label">
    <div v-if="error" class="error">{{ error }}</div>
    <button class="button green btn-m">{{ $t('save') }}</button>
  </form>
</template>

<script>
import { API } from '@/API'

export default {
  name: 'CreateAPIToken',
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      label: '',
      error: null
    }
  },
  methods: {
    async submit () {
      this.loading = true
      this.error = null
      try {
        const res = await API.createAPIToken(this.clientId, { label: this.label })
        this.$emit('new-token', res)
        this.label = ''
      } catch (e) {
        this.error = e
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.error {
  color: red;
}
</style>
