<template>
  <ClientLayout>
    <div class="center-container">
      <VueMarkdown :source="$t('apiTokenIntro')" />
      <div v-if="tokenCreatedInfo" class="info">
        <p class="info-intro">{{ $t('tokenCreateSuccess') }}</p>
        <table class="full-width">
          <tbody>
            <tr>
              <th>Username</th>
              <td>{{ tokenCreatedInfo.username }}</td>
            </tr>
            <tr>
              <th>Password</th>
              <td>{{ tokenCreatedInfo.password }}</td>
            </tr>
          </tbody>
        </table>
        <div class="close" @click="tokenCreatedInfo = null">
          &times;
        </div>
      </div>
      <h2>{{ $t('tokens') }}</h2>
      <Loading v-if="!apiTokens" />
      <div v-else-if="apiTokens.length === 0">
        {{ $t('emptyApiTokens') }}
      </div>
      <table v-else class="full-width">
        <thead>
          <th>{{ $t('label') }}</th>
          <th>{{ $t('username') }}</th>
          <th>{{ $t('lastUsedAt') }}</th>
        </thead>
        <tbody>
          <Token
            v-for="(t, idx) of apiTokens"
            :key="t.uuid"
            :token="t"
            @delete-token="tokenDeleted(idx)"
          />
        </tbody>
      </table>
      <h2>{{ $t('createApiToken') }}</h2>
      <CreateAPIToken
        :client-id="clientId"
        @new-token="newToken"
      />
      <h2>Docs</h2>
      <div id="swagger-ui" class="swagger-ui" />
    </div>
  </ClientLayout>
</template>

<script>
import ClientLayout from '@/components/ClientLayout'
import CreateAPIToken from '@/components/CreateAPIToken'
import Token from '@/components/Token'
import Loading from '@/components/Loading'
import { API } from '@/API'

import openAPISchema from '@/openapi-schema.yml'
import 'swagger-ui/dist/swagger-ui.css'
import SwaggerUI from 'swagger-ui'

export default {
  name: 'ClientAPIToken',
  components: {
    ClientLayout,
    CreateAPIToken,
    Loading,
    Token
  },
  data () {
    return {
      apiTokens: null,
      tokenCreatedInfo: null
    }
  },
  computed: {
    clientId () {
      return this.$route.params.clientId || this.$store.state.clientUUID
    }
  },
  mounted () {
    this.listAPITokens()
    this.mountSwaggerUI()
  },
  methods: {
    mountSwaggerUI () {
      SwaggerUI({
        spec: openAPISchema,
        dom_id: '#swagger-ui'
      })
    },
    async listAPITokens () {
      try {
        this.apiTokens = await API.getAPITokens(this.clientId)
      } catch (e) {
        this.error = e
      }
    },
    newToken (ev) {
      this.apiTokens.push(ev)
      this.tokenCreatedInfo = ev
    },
    tokenDeleted (idx) {
      this.apiTokens.splice(idx, 1)
    }
  }
}
</script>

<style scoped>
.full-width {
  width: 100%;
}

.center-container {
  margin-top: 50px;
}

.info {
  position: relative;
  padding: 50px;
  background-color: #FFC72E;

  & .info-intro {
    margin-top: 0px;
    margin-bottom: 30px;
  }

  & .close {
    cursor: pointer;
    color: white;
    font-size: 45px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 5%;
    z-index: 1;
  }
}

h2 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.swagger-ui {
  padding-bottom: 50px;
  text-align: left;

  /* until fix get merged: https://github.com/swagger-api/swagger-ui/pull/7517 */
  & /deep/ .tablinks {
    background: none;
    border: 0;
    padding: 0;

    color: inherit;
    font-family: inherit;
    font-weight: inherit;
  }
}
</style>
