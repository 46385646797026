<template>
  <div class="container">
    <table>
      <thead>
        <tr>
          <th>{{ $t('adminAccessesEmail') }}</th>
          <th>{{ $t('adminAccessesLastConnection') }}</th>
          <th>{{ $t('adminAccessesClient') }}</th>
          <th>{{ $t('role') }}</th>
          <th>{{ $t('language') }}</th>
          <th>{{ $t('statusUser') }}</th>
          <th>{{ $t('actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <Account
          v-for="account of sortedAccounts"
          :key="account.uuid"
          :account="account"
          @reinvited="$emit('reinvited', account.uuid)"
          @deleted="$emit('deleted', account.uuid)"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import Account from './Account'
import { API } from '@/API'

export default {
  name: 'AccountList',
  components: {
    Account
  },
  props: {
    accounts: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      clients: undefined
    }
  },
  computed: {
    sortedAccounts () {
      if (!this.clients) {
        return []
      }
      return this.accounts
        .map(a => ({ ...a, clientName: a.clientUUID ? this.clients[a.clientUUID].name : '*' }))
        .sort((a, b) => a.clientName.localeCompare(b.clientName))
    }
  },
  async mounted () {
    const clients = await API.getClients()
    this.clients = {}
    for (const c of clients) {
      this.clients[c.uuid] = c
    }
  }
}
</script>

<style scoped>
/*
.container {
  height: 400px;
  overflow: auto;
}
*/

table {
  width: 100%;
  text-align: left;

  & thead {
    margin-bottom: 10px;

    & th {
      position: sticky;
      top: 0;
      background: white;

      padding: 10px 7px;
      text-transform: uppercase;
    }
  }

  & tbody {
    padding: 10px;

    & tr:first-child td {
      padding-top: 9px;
    }

    & tr:last-child td {
      padding-bottom: 9px;
    }

    & td {
      padding: 5px 7px;
    }
  }
}
</style>
