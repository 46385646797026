<template>
  <div
    :class="[' header-container', { 'admin': isAdmin } ]"
    :style="{ backgroundColor: bgColor }"
  >
    <div class="nav">
      <div class="logo-container">
        <img
          v-if="isDefaultLogo"
          class="opo-logo"
          src="@/assets/logo-opo-creations.svg"
        >
        <div
          v-else-if="clientLogo"
          class="client-logo"
          :style="{ backgroundImage: `url(${clientLogo})` }"
        />
      </div>
      <div class="pacman-container">
        <img
          v-if="titleLogo"
          class="title-logo"
          :src="titleLogo"
        >
        <img
          v-else
          class="pacman"
          src="@/assets/header-pacman.svg"
        >
      </div>
      <div class="actions">
        <router-link
          class="my-account"
          :to="{ name: homeRoute }"
        >
          {{ $t('home') }}
        </router-link>
        |
        <template v-if="isLogged">
          <router-link
            class="my-account"
            :to="{ name: 'my-account' }"
          >
            {{ $t('myAccount') }}
          </router-link>
          |
        </template>
        <LanguageSwitch />
        <span
          v-if="isLogged"
          class="separator"
        >
          |
        </span>
        <Logout
          v-if="isLogged"
          :handler="logout"
        />
      </div>
      <!--
      <img
        src="@/assets/mission_gdpr_featuring.svg"
        alt="Mission GDPR"
      >
      -->
    </div>
  </div>
</template>

<script>
import Logout from '@/components/Logout'
import LanguageSwitch from '@/components/LanguageSwitch'
import { API, removeToken } from '@/API'

export default {
  name: 'MyHeader',
  components: {
    Logout,
    LanguageSwitch
  },
  props: {
    bgColor: {
      type: String,
      required: false,
      default: null
    },
    titleLogo: {
      type: String,
      required: false,
      default: null
    },
    clientId: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      client: null
    }
  },
  computed: {
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    isLogged () {
      return this.$store.state.logged
    },
    isDefaultLogo () {
      return (
        !this.isLogged ||
        ['admin-account-list', 'admin-client-list'].includes(this.$route.name)
      )
    },
    clientLogo () {
      if (!this.client) {
        return null
      }
      return this.client.logoURL
    },
    homeRoute () {
      const role = this.$store.state.role
      if (role === 'admin') {
        return 'admin-client-list'
      } else {
        return 'pack-list'
      }
    }
  },
  watch: {
    clientId () {
      this.fetchClient()
    }
  },
  async mounted () {
    await this.fetchClient()
  },
  methods: {
    async fetchClient () {
      const clientId = this.$route.params.clientId || this.$store.state.clientUUID || this.clientId
      if (!clientId) {
        return
      }
      this.client = await API.getClient(clientId)
    },
    logout () {
      removeToken()
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.header-container {
  background-color: #E95E38;
  color: white;
}

/*
.header-container.admin {
    background-color: red;
}
*/

.nav {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 40px;

  & > * {
    width: calc(100% / 3);
    /* flex-grow: 1; */

    &:first-child {
      text-align: left;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  & > * {
    margin: 5px;
  }
}

.pacman-container {
  height: 90%;
}

.logo-container {
  height: 100%;
}

img {
  max-height: 100%;
}

.client-logo {
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

.my-account {
  color: white;
  text-decoration: none;
  margin: 10px 10px;
}
</style>
