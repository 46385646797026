<template>
  <div>
    <Header :client-id="user ? user.clientUUID : null" />
    <router-link v-if="backButton" class="nav-btn" :to="backButton.to">
      &#9664; {{ backButton.text }}
    </router-link>
    <div class="center-container">
      <template v-if="errors">
        <p v-for="(err, idx) of errors" :key="idx" class="error">
          {{ err }}
        </p>
      </template>
      <Loading v-else-if="!user || !logs || !packs" />
      <template v-else>
        <h2>{{ $t('userInfos') }}</h2>
        <table v-if="!userEdit" class="user-infos">
          <tr>
            <td>{{ $t('email') }}</td>
            <td>{{ user.email }}</td>
          </tr>
          <tr>
            <td>{{ $t('firstName') }}</td>
            <td>{{ user.firstName }}</td>
          </tr>
          <tr>
            <td>{{ $t('lastName') }}</td>
            <td>{{ user.lastName }}</td>
          </tr>
          <tr>
            <td>{{ $t('language') }}</td>
            <td>{{ languageText }}</td>
          </tr>
          <template v-if="user.branch">
            <tr>
              <td>{{ $t('branch') }}</td>
              <td>{{ user.branch }}</td>
            </tr>
          </template>
          <tr>
            <td>{{ $t('timezone') }}</td>
            <td>{{ user.timezone }}</td>
          </tr>
        </table>
        <form v-if="userEdit" class="edit-form" @submit.prevent="submitEdit">
          <table class="user-infos">
            <!-- email is not editable -->
            <tr>
              <td>{{ $t('email') }}</td>
              <td>{{ userEdit.email }}</td>
            </tr>
            <tr>
              <td>{{ $t('firstName') }}</td>
              <td><input v-model="userEdit.firstName" type="text"></td>
            </tr>
            <tr>
              <td>{{ $t('lastName') }}</td>
              <td><input v-model="userEdit.lastName" type="text"></td>
            </tr>
            <tr>
              <td>{{ $t('language') }}</td>
              <td><LanguageInput v-model="userEdit.language" /></td>
            </tr>
            <tr>
              <td>{{ $t('branch') }}</td>
              <td><input v-model="userEdit.branch" :required="user && user.branch && user.branch.length > 0"></td>
            </tr>
            <tr>
              <td>{{ $t('timezone') }}</td>
              <td><TimezoneInput v-model="userEdit.timezone" /></td>
            </tr>
          </table>
          <p v-if="editError" class="error">
            {{ editError }}
          </p>
          <button class="button btn-m" type="button" @click="cancelEdit">
            {{ $t('cancel') }}
          </button>
          <button class="button green btn-m">
            {{ editLoading ? $t('loading') : $t('save') }}
          </button>
        </form>
        <template v-if="!userEdit">
          <button class="button yellow btn-m" @click="startEdit">
            {{ $t('edit') }}
          </button>
          <button class="button red btn-m" :disabled="deleteLoading" @click="deleteUser">
            {{ deleteLoading ? $t('loading') : $t('delete') }}
          </button>
        </template>
        <p v-if="deleteError" class="error">
          {{ deleteError }}
        </p>
        <h2 class="title-separator">
          {{ $t('emailsSent') }}
        </h2>
        <div v-if="logs.length === 0">
          {{ $t('noEmailsSent') }}
        </div>
        <table v-else class="logs">
          <tr>
            <th>{{ $t('category') }}</th>
            <th>{{ $t('sentAt') }}</th>
            <th>{{ $t('learning') }}</th>
          </tr>
          <tr v-for="log of logs" :key="log.uuid">
            <td :class="[ 'category', `category-${log.category}` ]">
              {{ categoryTranslate[log.category] }}
            </td>
            <td>{{ new Date(log.sentAt).toLocaleString($i18n.locale) }}</td>
            <td>
              <router-link v-if="log.packUuid" :to="{ name: 'pack-detail', params: { packId : log.packUuid } }">
                {{ packs[log.packUuid].name }}
              </router-link>
              <p v-else class="nomargin">
                {{ $t('multiLearning') }}
              </p>
            </td>
          </tr>
        </table>
        <h2 class="title-separator">
          {{ $t('registrations') }}
        </h2>
        <div class="center-container pack-list">
          <Pack
            v-for="pack of packs"
            :key="pack.uuid"
            :pack="pack"
            :thumbnail="thumbnails[pack.datoThemeId] ? thumbnails[pack.datoThemeId].url: ''"
            :deletable="false"
            @deleted="$emit('deleted', $event)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import langs from 'langs'
import Header from '@/components/Header'
import Loading from '@/components/Loading'
import TimezoneInput from '@/components/data/TimezoneInput'
import LanguageInput from '@/components/data/LanguageInput'
import { API } from '@/API'
import GetThemesThumbnail from '@/graphql/GetThemesThumbnail.gql'
import Pack from '@/components/pack-list/Pack'

export default {
  components: {
    Header,
    Loading,
    TimezoneInput,
    LanguageInput,
    Pack
  },
  data () {
    return {
      prevRoute: null,
      logs: null,
      user: null,
      packs: null,
      errors: null,

      // delete
      deleteLoading: false,
      deleteError: null,

      // edit
      userEdit: false,
      editLoading: false,
      editError: null,

      // registrations
      registrations: [],
      allThemes: []
    }
  },
  // https://stackoverflow.com/a/53789212
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  apollo: {
    // this request will be stored in ApolloCache
    allThemes: {
      query: GetThemesThumbnail,
      variables () {
        return {
          locale: this.$i18n.locale
        }
      }
    }
  },
  computed: {
    thumbnails () {
      // converts array to object for faster access
      if (!this.allThemes) {
        return {}
      }
      const ret = this.allThemes.reduce((obj, item) => {
        if (item.id && item.thumbnail.url) {
          obj[item.id] = item.thumbnail
        }
        return obj
      }, {})
      return ret
    },
    backButton () {
      // this view is accessible from mutple views
      // the back button should send back users to this page
      if (this.prevRoute && this.prevRoute.name === 'pack-detail') {
        return {
          text: this.$t('navLearning'),
          to: this.prevRoute
        }
      }
      if (this.user) {
        const learnerRoute = () => {
          if (this.$store.getters.isAdmin) {
            return { name: 'admin-learner-list', params: { clientId: this.user.clientUUID } }
          }
          return { name: 'learner-list' }
        }
        return {
          text: this.$t('navClients'),
          to: learnerRoute()
        }
      }
      return null
    },
    languageText () {
      if (!this.user.language) {
        return
      }
      const found = langs.where('1', this.user.language)
      return found?.local
    },
    categoryTranslate () {
      return {
        invite: this.$t('emailTagInvite'),
        'invite-multi-learning': this.$t('emailTagInviteMultiLearning'),
        diploma: this.$t('emailTagDiploma'),
        reminder: this.$t('emailTagReminder')
      }
    }
  },
  async mounted () {
    const { learnerId } = this.$route.params
    this.errors = null
    const setError = (err, context) => {
      this.errors = this.errors ?? []
      this.errors.push(new Error(`${context}: ${err.message}`))
    }

    API.getEmailLogs(learnerId)
      .then(({ logs }) => { this.logs = logs })
      .catch((err) => setError(err, 'getEmailLogs'))

    API.getUserRegistrations(learnerId)
      .then(({ packs }) => { this.registrations = packs })
      .catch((err) => setError(err, 'getUserRegistrations'))

    try {
      const { user } = await API.getUser(learnerId)
      this.user = user
    } catch (err) {
      setError(err, 'getUser')
      return
    }

    try {
      const packs = await API.getPacks(this.user.clientUUID)
      this.packs = {}
      for (const p of packs) {
        this.packs[p.uuid] = p
      }
    } catch (err) {
      setError(err, 'getPacks')
    }
  },
  methods: {
    async deleteUser () {
      try {
        await this.$dialog.confirm(this.$t('confirmUserDeletion'), {
          type: 'hard',
          verification: this.user.email,
          verificationHelp: this.$t('confirmHelp'),
          okText: this.$t('confirmOk'),
          cancelText: this.$t('confirmCancel')
        })
      } catch (e) {
        return
      }
      this.deleteLoading = true
      try {
        await API.deleteUser(this.user.uuid)
      } catch (e) {
        console.error('Unable to delete pack', e)
        this.deleteError = e
      } finally {
        this.deleteLoading = false
      }
      this.$router.push(this.backButton.to)
    },
    startEdit () {
      this.userEdit = {
        ...this.user
      }
    },
    cancelEdit () {
      this.userEdit = null
    },
    async submitEdit () {
      this.editLoading = true
      if (this.userEdit.branch === '') {
        this.userEdit.branch = undefined
      }
      try {
        const { user } = await API.editUser(this.userEdit)
        this.user = user
        this.userEdit = null
      } catch (e) {
        this.editError = e
      } finally {
        this.editLoading = false
      }
    }
  }
}
</script>

<style scoped>
.title-separator {
  margin-top: 80px;
  margin-bottom: 60px;
}

.error {
  color: red;
}

.category {
  border-radius: 5px;
}

.category-invite {
  background-color: #15aabf;
}

.category-invite-multi-learning {
  color: black;
  background-color: #F0F0F0;
}

.category-diploma {
  color: white;
  background-color: #22864e;
}

.category-reminder {
  color: black;
  background-color: var(--primary-yellow-color);
}

.user-infos {
  text-align: left;
  border-spacing: 10px;
  width: 100%;
}

.user-infos td:first-child {
  font-weight: bold;
}

.user-infos td:not(:first-child) {
  padding: 0 40px;
}

.user-infos td:last-child {
  width: 80%;
}

.edit-form {
  & input,
  & /deep/ select {
    margin: 0 !important;
    width: 100%;
    box-sizing: border-box;
  }
}

.logs {
  width: 100%;
}

.pack-list {
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: calc(100% / 5 - (10px * 2));
    margin: 10px;
  }
}

.btn-m {
  margin: 10px;
}

.nomargin {
  margin: 0;
}
</style>
