<template>
  <div>
    <Header />

    <router-link
      v-if="$store.getters.isAdmin"
      class="nav-btn"
      :to="{ name: 'admin-client-list' }"
    >
      &#9664; {{ $t('navClients') }}
    </router-link>

    <div class="center-container">
      <div class="nav">
        <router-link :to="routes.learning">
          {{ $t('learningsTitle') }}
        </router-link>
        <div class="separator">|</div>
        <router-link :to="routes.learner">
          {{ capitalize($t('users')) }}
        </router-link>
        <div class="separator">|</div>
        <router-link :to="routes.apiToken">
          {{ capitalize($t('api')) }}
        </router-link>
        <div class="separator">|</div>
        <router-link :to="routes.emails">
          {{ capitalize($t('emails')) }}
        </router-link>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'ClientLayout',
  components: {
    Header
  },
  computed: {
    routes () {
      if (this.$store.getters.isAdmin) {
        const { clientId } = this.$route.params
        return {
          learning: { name: 'admin-pack-list', params: { clientId } },
          learner: { name: 'admin-learner-list', params: { clientId } },
          apiToken: { name: 'admin-api', params: { clientId } },
          emails: { name: 'admin-client-emails', params: { clientId } }
        }
      }
      return {
        learning: { name: 'pack-list' },
        learner: { name: 'learner-list' },
        apiToken: { name: 'api' },
        emails: { name: 'client-emails' }
      }
    }
  },
  methods: {
    capitalize (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  }
}
</script>

<style scoped>
.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 20px;

  & > * {
    margin: 10px;
    font-size: 45px;
    font-weight: 200;
  }
}

a {
  color: #cccccc;
  text-decoration: none;

  &.router-link-active {
    color: #e95e38ff;
  }
}

.separator {
  color: #cccccc;
}
</style>
