<template>
  <div>
    <Header />
    <h2>{{ $t('confirmEmailAddress') }}</h2>
    <Loading
      v-if="loading"
      class="loading"
    />
    <div
      v-if="error"
      class="error"
    >
      {{ error }}
    </div>
    <div v-if="success">
      <p>{{ $t('confirmEmailAddressSuccess') }}</p>
      <router-link
        to="/"
        class="button rounded yellow"
      >
        {{ $t('goToHomepage') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Loading from '@/components/Loading'
import { API, formatAPIErr } from '@/API'

export default {
  name: 'ConfirmEmail',
  components: {
    Header,
    Loading
  },
  data () {
    return {
      loading: false,
      error: '',
      success: false
    }
  },
  async mounted () {
    const accountId = this.$route.query.account
    if (!accountId) {
      this.error = 'Missing accountId'
      return
    }
    const token = this.$route.query.token
    if (!token) {
      this.error = 'Missing token'
      return
    }
    this.loading = true
    this.error = ''
    this.success = false
    try {
      await API.confirmEmailUpdate(accountId, token)
      this.success = true
    } catch (e) {
      this.error = formatAPIErr(e)
    } finally {
      this.loading = false
    }
  }
}
</script>

<style scoped>
.error {
  color: red;
}
</style>
