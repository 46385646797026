<template>
  <div>
    <Header />
    <div class="content">
      <h2>{{ $t('loginTitle') }}</h2>
      <VueMarkdown
        class="intro-text"
        :source="$t('loginText')"
      />
    </div>
    <LoginForm :submit="submit" />
    <router-link :to="{ name: 'forgot-password' }">
      {{ $t('forgotPassword') }}
    </router-link>
  </div>
</template>

<script>
import { API, getToken, persistToken, getJwtPayload } from '@/API'
import Header from '@/components/Header'
import LoginForm from '@/components/LoginForm'

export default {
  name: 'LoginAccess',
  components: {
    Header,
    LoginForm
  },
  async mounted () {
    if (!getToken()) {
      return
    }
    try {
      const newToken = await API.refreshToken()
      API.setToken(newToken)
      persistToken()
      this.onSuccess()
    } catch (e) {
      // Ignore
    }
  },
  methods: {
    async onSuccess () {
      const { role, clientUuid } = getJwtPayload()

      this.$store.commit('setLogged')
      this.$store.commit('setRole', role)
      if (role === 'access') {
        this.$store.commit('setClientUUID', clientUuid)
      }

      const account = await API.getAccount()
      this.$store.commit('setAccount', account)

      let nextPath = this.$route.query.next
      if (!nextPath) {
        if (role === 'admin') {
          nextPath = '/client'
        } else {
          nextPath = '/pack'
        }
      }
      const getOtherQuery = ({ next, ...other }) => other
      this.$router.replace({ path: nextPath, query: getOtherQuery(this.$route.query) })
    },
    async submit (user, password) {
      if (await API.checkAuth(user, password) !== true) {
        alert('Wrong access')
        return
      }
      this.onSuccess()
    }
  }
}
</script>

<style scoped>
.content {
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.intro-text {
  text-align: left;
}

h2 {
  max-width: 360px;
  margin: 40px auto;
}
</style>
