<template>
  <DashboardLayout class="container">
    <ClientSearch
      :value="clientSearch"
      @input="setClientSearch($event)"
    />
    <ClientList :search="clientSearch" />
  </DashboardLayout>
</template>

<script>
import DashboardLayout from '@/views/DashboardLayout'
import ClientList from '@/components/admin/ClientList'
import ClientSearch from '@/components/admin/ClientSearch'

import { mapMutations, mapState } from 'vuex'

export default {
  name: 'AdminClientList',
  components: {
    DashboardLayout,
    ClientSearch,
    ClientList
  },
  computed: {
    ...mapState([
      'clientSearch'
    ])
  },
  methods: {
    ...mapMutations([
      'setClientSearch'
    ])
  }
}
</script>

<style scoped>
.container *:not(:last-child) {
  margin-bottom: 35px;
}
</style>
