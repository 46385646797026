import axios from 'axios'
import jwtDecode from 'jwt-decode'

const config = {
  baseURL: process.env.VUE_APP_RECORDS_API_URL || 'https://api.oeilpouroeil-learning.com'
}

const tokenLSKey = 'token'
const expireLSKey = 'token-expire'

class Token {
  constructor ({ token, expire }) {
    this.token = token
    this.expire = new Date(expire)
  }

  get isExpired () {
    const now = new Date()
    return now > this.expire
  }

  get httpHeaders () {
    return {
      Authorization: 'Bearer ' + this.token
    }
  }
}

let token

export function persistToken () {
  const { localStorage } = window
  localStorage.setItem(tokenLSKey, token.token)
  localStorage.setItem(expireLSKey, token.expire)
}

export function removeToken () {
  const { localStorage } = window
  localStorage.removeItem(tokenLSKey)
  localStorage.removeItem(expireLSKey)
  token = undefined
}

function loadToken () {
  const { localStorage } = window
  const token = localStorage.getItem(tokenLSKey)
  const expire = localStorage.getItem(expireLSKey)
  if (!token || !expire) {
    removeToken()
    return
  }
  API.setToken({ token, expire })
}

function getToken () {
  if (!token) {
    return undefined
  }
  return token.token
}

function getJwtPayload () {
  if (!token) {
    return undefined
  }
  return jwtDecode(token.token)
}

// Used to make anonymous API calls
const api = axios.create(config)

// Used to make authenticated API calls
// Need to be set with setAuthApi
let authApi = axios.create(config)
authApi.interceptors.request.use(config => {
  console.error('Using authAPI without token !')
  return config
})

function setAuthApi (refreshToken) {
  authApi = axios.create(config)

  authApi.interceptors.request.use(async (config) => {
    if (!token) {
      return config
    }
    // refresh token if possible
    if (token.isExpired) {
      const data = await refreshToken()
      token = new Token(data)
      persistToken()
    }
    // add token in headers
    const headers = token.httpHeaders
    return { ...config, headers }
  })
}

function downloadFile (blob, fileName) {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

class API {
  static token = null
  static tokenExpire = null

  static async checkAuth (user, pass) {
    try {
      const resp = await api.post('/access/login', {
        username: user,
        password: pass
      })
      this.setToken(resp.data)
      persistToken()
      return true
    } catch (error) {
      // 401
      return false
    }
  }

  static setToken (data) {
    token = new Token(data)
    setAuthApi(this.refreshToken)
  }

  static async refreshToken () {
    // refreshToken uses api instead of authApi to avoid recursive loop from interceptors
    const resp = await api.get('/access/refresh_token', { headers: token.httpHeaders })
    return resp.data
  }

  static async getAccount () {
    const resp = await authApi.get('/access/current-account')
    return resp.data.account
  }

  static async getClientId () {
    const resp = await authApi.get('/access/client-id')
    return resp.data.clientID
  }

  static async getClient (clientID) {
    const resp = await authApi.get(`/access/client/${clientID}`)
    return resp.data.client
  }

  static async getPack (packID) {
    const resp = await authApi.get(`/access/pack/${packID}`)
    return resp.data.pack
  }

  static async getPacks (clientID) {
    const res = await authApi.get(`/access/client/${clientID}/pack`)
    return res.data.packs
  }

  static async getClientUsers (clientID) {
    const res = await authApi.get(`/access/client/${clientID}/user`)
    return res.data.users
  }

  static async getUsers (packID) {
    const resp = await authApi.get(`/access/pack/${packID}/user`)
    return resp.data.users
  }

  static async getModule (moduleID) {
    const resp = await authApi.get(`/access/module/${moduleID}`)
    return resp.data.module
  }

  static async getModules (packID) {
    const resp = await authApi.get(`/access/pack/${packID}/module`)
    return resp.data.modules
  }

  static async getPackProgress (packID) {
    const res = await authApi.get(`/access/pack/${packID}/user-progress`)
    return res.data.progress
  }

  static async downloadPackProgress (packID, fmt) {
    const query = new URLSearchParams({ format: fmt })
    const resp = await authApi.get(`/access/pack/${packID}/user-progress-export?${query.toString()}`, {
      responseType: 'blob'
    })
    downloadFile(new Blob([resp.data]), resp.headers['x-suggested-filename'])
  }

  static async getModuleProgress (moduleID) {
    const res = await authApi.get(`/access/module/${moduleID}/user-progress`)
    return res.data.progress
  }

  static async downloadModuleProgressExport (moduleID, fmt) {
    const query = new URLSearchParams({ format: fmt })
    const resp = await authApi.get(`/access/module/${moduleID}/user-progress-export?${query.toString()}`, {
      responseType: 'blob'
    })
    downloadFile(new Blob([resp.data]), resp.headers['x-suggested-filename'])
  }

  static async subscribeUsers (packID, users) {
    const res = await authApi.post(`/access/pack/${packID}/subscribe-users`, {
      users
    })
    return res.data.users
  }

  static async inviteSelectedUsers (packID, users) {
    const res = await authApi.post(`/access/pack/${packID}/invite-selected-users`, {
      users
    })
    return res.data.users
  }

  static async inviteCurrentUsers (packID, force) {
    const res = await authApi.post(`/access/pack/${packID}/invite-current-users`, {
      force
    })
    return res.data.users
  }

  static async inviteMultiLearning (clientID, packs, users) {
    const res = await authApi.post(`/access/client/${clientID}/invite-users`, {
      packs,
      users
    })
    return res.data.users
  }

  static async sendDiploma (packID, users) {
    const res = await authApi.post(`/access/pack/${packID}/send-diploma`, {
      users
    })
    return res.data
  }

  static async deleteModule (moduleId) {
    const res = await authApi.delete(`/access/module/${moduleId}`)
    return res.data
  }

  static async deletePack (packId) {
    const res = await authApi.delete(`/access/pack/${packId}`)
    return res.data
  }

  static async deletePackUser (packUUID, userUUID) {
    const res = await authApi.delete(`/access/pack/${packUUID}/user/${userUUID}`)
    return res.data
  }

  static async deleteUser (userUUID) {
    const res = await authApi.delete(`/access/user/${userUUID}`)
    return res.data
  }

  static async editUser (user) {
    const res = await authApi.patch(`/access/user/${user.uuid}`, user)
    return res.data
  }

  static async getUser (userId) {
    const res = await authApi.get(`/access/user/${userId}`)
    return res.data
  }

  static async getEmailLogs (userId) {
    const res = await authApi.get(`/access/user/${userId}/email-log`)
    return res.data
  }

  static async getUserRegistrations (userId) {
    const res = await authApi.get(`/access/user/${userId}/pack`)
    return res.data
  }

  static async getAPITokens (clientId) {
    const res = await authApi.get(`/access/client/${clientId}/api-token`)
    return res.data.tokens
  }

  static async createAPIToken (clientId, { label }) {
    const res = await authApi.post(`/access/client/${clientId}/api-token`, { label })
    return res.data
  }

  static async deleteAPIToken (uuid) {
    const res = await authApi.delete(`/access/api-token/${uuid}`)
    return res.data
  }

  // Admin routes
  static async getAccounts () {
    const res = await authApi.get('/access/account')
    return res.data.accounts
  }

  static async addAccount (payload) {
    const res = await authApi.post('/access/account', payload)
    return res.data
  }

  static async getClients () {
    const res = await authApi.get('/access/client')
    return res.data.clients
  }

  static async deleteClient (clientUUID) {
    const res = await authApi.delete(`/access/client/${clientUUID}`)
    return res.data
  }

  // Accounts routes

  static async deleteAccount (accountId) {
    const res = await authApi.delete(`/access/account/${accountId}`)
    return res.data
  }

  static async updateAccount (accountId, payload) {
    const res = await authApi.patch(`/access/account/${accountId}`, payload)
    return res.data
  }

  static async confirmEmailUpdate (accountUuid, token) {
    const res = await api.post('/access/confirm-email-update', { accountUuid, token })
    return res.data
  }

  static async forgotPassword (email) {
    const res = await api.post('/access/forgot-password', { email })
    return res.data
  }

  static async resetPasswordCheckToken (accountUuid, token) {
    const res = await api.post('/access/reset-password-check-token', { accountUuid, token })
    return res.data
  }

  static async resetPassword (accountUuid, token, password) {
    const res = await api.post('/access/reset-password', { accountUuid, token, password })
    return res.data
  }

  static async inviteAccount ({ email, clientID, role, lang }) {
    const res = await authApi.post('/access/account/invite', { email, clientID, role, lang })
    return res.data
  }

  static async setupAccountCheckToken (accountUuid, token) {
    const res = await api.post('/access/setup-account-check-token', { accountUuid, token })
    return res.data
  }

  static async setupAccount (accountUuid, token, password) {
    const res = await api.post('/access/setup-account', { accountUuid, token, password })
    return res.data
  }

  static async inviteAccountReInvite (accountUuid) {
    const res = await authApi.post('/access/account/invite-again', { accountUuid })
    return res.data
  }

  static async checkPasswordStrength (password) {
    const res = await authApi.post('/access/password-validator', { password })
    return res.data
  }

  static async getInviteMultiLearningEmail (clientID) {
    const res = await authApi.get(`/access/client/${clientID}/invite-multi-learning-email`)
    return res.data
  }

  static async updateInviteMultiLearningEmail (clientID, email) {
    const res = await authApi.put(`/access/client/${clientID}/invite-multi-learning-email`, email)
    return res.data
  }

  static async deleteInviteMultiLearningEmail (clientID) {
    const res = await authApi.delete(`/access/client/${clientID}/invite-multi-learning-email`)
    return res.data
  }

  static async getInviteLearningEmail (packID) {
    const res = await authApi.get(`/access/pack/${packID}/invite-email`)
    return res.data
  }

  static async updateInviteLearningEmail (packID, email) {
    const res = await authApi.put(`/access/pack/${packID}/invite-email`, email)
    return res.data
  }

  static async deleteInviteLearningEmail (packID) {
    const res = await authApi.delete(`/access/pack/${packID}/invite-email`)
    return res.data
  }

  static async getDiplomaEmail (packID) {
    const res = await authApi.get(`/access/pack/${packID}/diploma-email`)
    return res.data
  }

  static async updateDiplomaEmail (packID, email) {
    const res = await authApi.put(`/access/pack/${packID}/diploma-email`, email)
    return res.data
  }

  static async deleteDiplomaEmail (packID) {
    const res = await authApi.delete(`/access/pack/${packID}/diploma-email`)
    return res.data
  }

  static async getPackSettings (packID) {
    const res = await authApi.get(`/access/pack/${packID}/settings`)
    return res.data
  }

  static async updatePackSettings (packID, body) {
    const res = await authApi.patch(`/access/pack/${packID}/settings`, body)
    return res.data
  }

  static async getReminderTemplates (packID) {
    const res = await authApi.get(`/access/pack/${packID}/reminder-email`)
    return res.data.emails
  }

  static async getReminderTemplate (id) {
    const res = await authApi.get(`/access/reminder-email/${id}`)
    return res.data
  }

  static async createReminderTemplate (body) {
    const res = await authApi.post('/access/reminder-email', body)
    return res.data
  }

  static async updateReminderTemplate (uuid, body) {
    const res = await authApi.patch(`/access/reminder-email/${uuid}`, body)
    return res.data
  }

  static async deleteReminderTemplate (uuid) {
    const res = await authApi.delete(`/access/reminder-email/${uuid}`)
    return res.data
  }

  static async sendReminderTemplate (uuid, body) {
    const res = await authApi.post(`/access/reminder-email/${uuid}/send`, body)
    return res.data
  }

  static async getReminderRules (packId) {
    const res = await authApi.get(`/access/pack/${packId}/reminder-rule`)
    return res.data.rules
  }

  static async createReminderRule (body) {
    const res = await authApi.post('/access/reminder-rule', body)
    return res.data
  }

  static async deleteReminderRule (id) {
    const res = await authApi.delete(`/access/reminder-rule/${id}`)
    return res.data
  }

  // only available to admin
  static async getCustomTemplates () {
    const res = await authApi.get('/access/custom-template')
    return res.data.templates
  }
}

// e is the error from catch
function formatAPIErr (e) {
  const apiErrors = []
  apiErrors.push(`API error: ${e.toString()}`)
  if (e.response && e.response.data && e.response.data.error) {
    apiErrors.push(e.response.data.error)
  } else if (e.response && e.response.data) {
    apiErrors.push(JSON.stringify(e.response.data))
  }
  return apiErrors.join(': ')
}

// load token from local storage on page load
loadToken()

export { API, getToken, getJwtPayload, formatAPIErr }
