import Vue from 'vue'
import Router from 'vue-router'

import ClientList from '@/views/ClientList'
import AccountList from '@/views/AccountList'

import Login from '@/views/Login'
import PackList from '@/views/PackList'
import PackDetail from '@/views/PackDetail'
import LearningSettingsInvite from '@/views/LearningSettingsInvite'
import LearningSettingsDiploma from '@/views/LearningSettingsDiploma'
import LearningSettingsReminders from '@/views/LearningSettingsReminders'
import LearningSettingsReminderCreate from '@/views/LearningSettingsReminderCreate'
import LearningSettingsReminderUpdate from '@/views/LearningSettingsReminderUpdate'

import ModuleDetail from '@/views/ModuleDetail'
import LearnerDetail from '@/views/LearnerDetail'
import LearnerList from '@/views/LearnerList'
import ClientAPI from '@/views/ClientAPI'
import ClientEmails from '@/views/ClientEmails'

import Account from '@/views/Account'
import ConfirmEmail from '@/views/ConfirmEmail'
import ForgotPassword from '@/views/ForgotPassword'
import ResetPassword from '@/views/ResetPassword'
import SetupAccount from '@/views/SetupAccount'

import Forbidden from '@/views/Forbidden'
import NotFound from '@/views/NotFound'

import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/my-account',
      name: 'my-account',
      component: Account,
      meta: { requiresAuth: true }
    },
    {
      path: '/confirm-email',
      name: 'confirm-email',
      component: ConfirmEmail
    },
    {
      path: '/setup-account',
      name: 'setup-account',
      component: SetupAccount
    },
    {
      path: '/pack',
      name: 'pack-list',
      component: PackList,
      meta: { requiresAccessAuth: true }
    },
    {
      path: '/emails',
      name: 'client-emails',
      component: ClientEmails,
      meta: { requiresAccessAuth: true }
    },
    {
      path: '/learner',
      name: 'learner-list',
      component: LearnerList,
      meta: { requiresAccessAuth: true }
    },
    {
      path: '/api',
      name: 'api',
      component: ClientAPI,
      meta: { requiresAccessAuth: true }
    },
    {
      path: '/pack/:packId',
      name: 'pack-detail',
      component: PackDetail,
      meta: { requiresAuth: true }
    },
    {
      path: '/pack/:packId/settings/invite',
      name: 'pack-settings-invite',
      component: LearningSettingsInvite,
      meta: { requiresAuth: true }
    },
    {
      path: '/pack/:packId/settings/diploma',
      name: 'pack-settings-diploma',
      component: LearningSettingsDiploma,
      meta: { requiresAuth: true }
    },
    {
      path: '/pack/:packId/settings/reminder',
      name: 'pack-settings-reminder',
      component: LearningSettingsReminders,
      meta: { requiresAuth: true }
    },
    {
      path: '/pack/:packId/settings/reminder/create',
      name: 'pack-settings-reminder-create',
      component: LearningSettingsReminderCreate,
      meta: { requiresAuth: true }
    },
    {
      path: '/pack/:packId/settings/reminder/:templateId/update',
      name: 'pack-settings-reminder-update',
      component: LearningSettingsReminderUpdate,
      meta: { requiresAuth: true }
    },
    {
      path: '/module/:moduleId',
      name: 'module-detail',
      component: ModuleDetail,
      meta: { requiresAuth: true }
    },
    {
      path: '/learner/:learnerId',
      name: 'learner-detail',
      component: LearnerDetail,
      meta: { requiresAuth: true }
    },
    {
      path: '/client',
      name: 'admin-client-list',
      component: ClientList,
      meta: { requiresAdminAuth: true }
    },
    {
      path: '/account',
      name: 'admin-account-list',
      component: AccountList,
      meta: { requiresAdminAuth: true }
    },
    {
      path: '/client/:clientId/pack',
      name: 'admin-pack-list',
      component: PackList,
      meta: { requiresAdminAuth: true }
    },
    {
      path: '/client/:clientId/learner',
      name: 'admin-learner-list',
      component: LearnerList,
      meta: { requiresAdminAuth: true }
    },
    {
      path: '/client/:clientId/api',
      name: 'admin-api',
      component: ClientAPI,
      meta: { requiresAdminAuth: true }
    },
    {
      path: '/client/:clientId/emails',
      name: 'admin-client-emails',
      component: ClientEmails,
      meta: { requiresAdminAuth: true }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword
    },

    // Errors page
    {
      path: '/error/forbidden',
      name: 'error-forbidden',
      component: Forbidden
    },
    {
      path: '/error/not-found',
      name: 'error-not-found',
      component: NotFound
    },

    { path: '*', redirect: '/error/not-found' }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAccessAuth)) {
    if (!store.state.logged) {
      next({ path: '/login', query: { next: to.path, ...to.query }, replace: true })
    } else if (store.state.logged && !store.getters.isAccess) {
      next({ path: '/error/forbidden', replace: true })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (!store.state.logged) {
      next({ path: '/login', query: { next: to.path, ...to.query }, replace: true })
    } else if (store.state.logged && !store.getters.isAdmin) {
      next({ path: '/error/forbidden', replace: true })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.logged) {
      next({ path: '/login', query: { next: to.path, ...to.query }, replace: true })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
