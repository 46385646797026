<template>
  <div class="multi-select">
    <div
      v-for="l of learnings"
      :key="l.uuid"
      class="item"
    >
      <input
        :id="`checkbox-learning-${_uid}-${l.uuid}`"
        v-model="selected"
        :value="l.uuid"
        type="checkbox"
        class="input"
      >
      <label
        class="label"
        :for="`checkbox-learning-${_uid}-${l.uuid}`"
      >
        <img
          class="label-img"
          :src="l.thumbnail ? l.thumbnail.url : null"
          :alt="l.name"
        >
        <div
          v-if="selected.includes(l.uuid)"
          class="checkbox-selected"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path
              d="M0 0h24v24H0z"
              fill="none"
            />
            <path
              d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
              fill="#ffffff"
            />
          </svg>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MutliSelectLearning',
  props: {
    learnings: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected () {
      this.$emit('input', this.selected)
    }
  },
  created () {
    this.selectAll()
    this.$parent.$on('reset-form', this.resetState)
  },
  beforeDestroy () {
    this.$parent.$off('reset-form', this.resetState)
  },
  methods: {
    selectAll () {
      this.selected = this.learnings.map(l => l.uuid)
    },
    resetState () {
      // parent component calls form.reset()
      // so we need to unset fields and reset them
      // to let vue set checkbox values
      this.selected = []
      this.$nextTick(() => { this.selectAll() })
    }
  }
}
</script>

<style scoped>
.multi-select {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}
.input {
  display: none;
}
.label {
  position: relative;
  display: block;
  cursor: pointer;
  transition: transform 0.1s;
  transform: scale(0.95)
}
.label:after {
  content: '';
  position: absolute;
  z-index: 1;
  margin: auto;
  top: 0px;
  left: 0px;
  display: block;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;
  transition: opacity 0.1s;
}
.input:checked + .label {
  transform: scale(1);
}
.input:checked + .label:after {
  opacity: 0.5;
}
.checkbox-selected {
  position: absolute;
  z-index: 2;
  height: 30px;
  margin: auto;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.label-img {
  box-shadow: 0px 0px 10px #00000047;
  display: block;
}
</style>
