<template>
  <div class="container">
    <button
      class="nostyle"
      @click="handler"
    >
      <p>{{ $t('logout') }}</p>
      <img
        class="icon"
        src="@/assets/icon-quit.svg"
      >
    </button>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  props: {
    handler: {
      type: Function,
      required: true
    }
  }
}
</script>

<style scoped>
.container {
  cursor: pointer;
}

button {
  display: flex;
  align-items: center;
  cursor: pointer;

  & > * {
    margin: 3px;
  }
}

.icon {
  height: 17px;
}
</style>
