<template>
  <div>
    <div class="collapse-excerpt-container">
      <table v-if="users" class="entities">
        <tr
          v-for="[domain, nbr] of visibleEntities"
          :key="domain"
        >
          <td>{{ domain }}</td>
          <td style="white-space: nowrap;">
            {{ nbr }}<img class="user" src="@/assets/user.svg">
          </td>
        </tr>
      </table>
      <div v-if="isCollapsible && isCollapsed" class="collapse-excerpt" />
    </div>
    <div v-if="isCollapsible" class="button-container">
      <button v-if="isCollapsed" class="button yellow small" @click="isCollapsed = false">
        {{ $t('showMore') }}
      </button>
      <button v-if="!isCollapsed" class="button yellow small" @click="isCollapsed = true">
        {{ $t('showLess') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Entities',
  props: {
    users: {
      type: Array,
      required: false,
      default: null
    }
  },
  data () {
    return {
      isCollapsed: true
    }
  },
  computed: {
    entities () {
      const res = {}
      for (const u of this.users) {
        const domain = this.parseDomain(u.email)
        if (res[domain] === undefined || res[domain] === null) {
          res[domain] = 0
        }
        res[domain] += 1
      }
      // array of array: ["example.com", occurences][]
      return Object.entries(res).sort(([d1, b], [d2, a]) => a - b)
    },
    visibleEntities () {
      if (this.isCollapsible && this.isCollapsed) {
        return this.entities.slice(0, this.collapseLen)
      }
      return this.entities
    },
    collapseLen () {
      return 10
    },
    isCollapsible () {
      return this.entities.length > this.collapseLen
    }
  },
  methods: {
    parseDomain (email) {
      return email.substring(email.indexOf('@'))
    }
  }
}
</script>

<style scoped>
table {
  width: 100%;
  max-width: 600px;
}

table td:first-child {
  width: 100%;
}

.user {
  width: 15px;
  margin-left: 10px;
}

.button.small {
  border-radius: 10px;
  font-size: 16px;
}

.collapse-excerpt-container {
  position: relative;
}

.collapse-excerpt::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, white 100%);
}

.button-container {
  text-align: center;
}
</style>
