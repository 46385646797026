<template>
  <div>
    <Loading v-if="initialLoading" />
    <template v-else>
      <p>{{ $t('selectRecipients') }}</p>
      <SelectUsers v-model="selectedUsers" :users="users" :progress="progress" />
      <button class="button" :disabled="loading || userIds.length === 0" @click="send">
        <Loading v-if="loading" class="small-loading loading-white" />
        <template v-else>
          {{ $t('sendReminder') }}
        </template>
      </button>
    </template>
  </div>
</template>

<script>
import { API } from '@/API'
import Loading from '@/components/Loading'
import SelectUsers from '@/components/SelectUsers'

export default {
  name: 'SendReminder',
  components: {
    Loading,
    SelectUsers
  },
  props: {
    packId: {
      type: String,
      required: true
    },
    template: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      initialLoading: true,
      loading: false,
      selectedUsers: {}
    }
  },
  computed: {
    userIds () {
      return Object.keys(this.selectedUsers).filter(u => this.selectedUsers[u] === true)
    }
  },
  async mounted () {
    await Promise.all([
      (async () => { this.users = await API.getUsers(this.packId) })(),
      (async () => { this.progress = await API.getPackProgress(this.packId) })()
    ])
    this.initialLoading = false
  },
  methods: {
    async send () {
      this.loading = true
      const users = []
      for (const uuid in this.selectedUsers) {
        if (this.selectedUsers[uuid] === true) {
          users.push(uuid)
        }
      }
      await API.sendReminderTemplate(this.template.uuid, { users })
      this.loading = false
      this.$emit('close')
      this.$toast.success(this.$t('emailsSent'))
    }
  }
}
</script>

<style scoped>
</style>
