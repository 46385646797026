<template>
  <ClientLayout>
    <Loading v-if="loading" />
    <div v-else class="container">
      <h3>{{ $t('titleInviteMultiLearningTemplate') }}</h3>
      <p>{{ $t('introInviteMultiLearningTemplate') }}</p>
      <EmailForm
        v-model="email"
        :default-email="defaultEmail"
        :locales="locales"
        :update-template="updateCustomTemplate"
        :delete-template="deleteCustomTemplate"
      />
    </div>
  </ClientLayout>
</template>

<script>
import ClientLayout from '@/components/ClientLayout'
import Loading from '@/components/Loading'
import GetDefaultInviteMultiLearningEmail from '@/graphql/GetDefaultInviteMultiLearningEmail.gql'
import EmailForm from '@/components/emails/EmailForm'
import { mapState } from 'vuex'
import { API } from '@/API'

export default {
  components: {
    EmailForm,
    ClientLayout,
    Loading
  },
  data () {
    return {
      apiLoading: true,
      client: null,
      defaultEmail: null,
      email: null
    }
  },
  apollo: {
    defaultEmail: {
      query: GetDefaultInviteMultiLearningEmail
    }
  },
  computed: {
    loading () {
      return this.apiLoading || this.$apollo.loading
    },
    locales () {
      if (this.loading) {
        return null
      }
      if (this.client.languages.length === 0) {
        return this.datoLocales
      }
      return this.client.languages
    },
    clientId () {
      return this.$route.params.clientId || this.$store.state.clientUUID
    },
    ...mapState([
      'datoLocales'
    ])
  },
  async mounted () {
    await Promise.all([
      this.getClient(),
      this.getInviteMultiLearningEmail()
    ])
    this.apiLoading = false
  },
  methods: {
    async getClient () {
      this.client = await API.getClient(this.clientId)
    },
    async getInviteMultiLearningEmail () {
      try {
        this.email = await API.getInviteMultiLearningEmail(this.clientId)
      } catch (e) {
        this.email = null // using default email
      }
    },
    updateCustomTemplate (body) {
      return API.updateInviteMultiLearningEmail(this.clientId, body)
    },
    deleteCustomTemplate () {
      return API.deleteInviteMultiLearningEmail(this.clientId)
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}
</style>
