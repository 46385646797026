<template>
  <span>
    <template v-if="status == 'completed'">
      {{ $t('recordsDone') }}<img
        class="picto"
        src="@/assets/complete.svg"
      >
    </template>
    <template v-else-if="status === 'in progress'">
      {{ $t('recordsInProgress') }}
    </template>
    <template v-else>
      {{ $t('recordsNotStarted') }}
    </template>
  </span>
</template>

<script>
export default {
  name: 'RecordStatus',
  props: {
    status: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.picto {
  width: 15px;
  margin-left: 10px;
}
</style>
