<template>
  <ClientLayout>
    <PackList
      :client-id="clientId"
      :packs="packs"
      @deleted="packs = packs.filter(p => p.uuid !== $event.uuid)"
    />
  </ClientLayout>
</template>

<script>
import { API } from '@/API'
import ClientLayout from '@/components/ClientLayout'
import PackList from '@/components/pack-list/PackList'

export default {
  name: 'AccessPackList',
  components: {
    ClientLayout,
    PackList
  },
  data () {
    return {
      packs: null
    }
  },
  computed: {
    clientId () {
      return this.$route.params.clientId || this.$store.state.clientUUID
    }
  },
  mounted () {
    this.setPacks()
  },
  methods: {
    async setPacks () {
      this.packs = await API.getPacks(this.clientId)
    }
  }
}
</script>

<style scoped>
</style>
