<template>
  <DashboardLayout class="container">
    <CreateAccount @new-account="accounts.unshift($event)" />
    <AccountList
      :accounts="accounts"
      @reinvited="accounts.find(a => a.uuid === $event).invitationExpired = false"
      @deleted="accounts = accounts.filter(a => a.uuid != $event)"
    />
  </DashboardLayout>
</template>

<script>
import DashboardLayout from '@/views/DashboardLayout'
import CreateAccount from '@/components/admin/CreateAccount.vue'
import AccountList from '@/components/admin/AccountList.vue'
import { API } from '@/API'

export default {
  name: 'AccountListView',
  components: {
    DashboardLayout,
    CreateAccount,
    AccountList
  },
  data () {
    return {
      accounts: []
    }
  },
  async mounted () {
    this.accounts = await API.getAccounts()
  }
}
</script>

<style scoped>
.container *:not(:last-child) {
  margin-bottom: 80px;
}
</style>
