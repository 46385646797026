<template>
  <div>
    <h3>{{ $t('reminderRulesTitle') }}</h3>
    <div class="list">
      <Loading v-if="rules === null || templates === null" />
      <template v-else>
        <div v-if="rules.length === 0" class="empty-list">
          {{ $t('emptyReminderRules') }}
        </div>
        <table v-else class="template-table">
          <tbody>
            <tr v-for="r of rules" :key="r.uuid">
              <td>{{ ruleString(r) }}</td>
              <td>
                <div class="actions">
                  <button
                    class="button red action-btn"
                    :disabled="deleteLoading"
                    :title="$t('delete')"
                    @click="deleteRule(r)"
                  >
                    <Loading v-if="deleteLoading" class="small-loading loading-white" />
                    <img v-else class="action-icon" src="@/assets/trash.svg">
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <button class="button green btn-m" @click="modalOpen = true">
          {{ $t('addReminderRule') }}
        </button>
      </template>
    </div>
    <Modal
      :title="$t('createReminderRuleTitle')"
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <ReminderRuleCreate
        v-if="modalOpen"
        :pack-id="packId"
        :templates="templates"
        @created="created"
      />
    </Modal>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import { API } from '@/API'
import { translateLearningStatus } from '@/utils/translation'
import * as Sentry from '@sentry/vue'

import Modal from '@/components/Modal'
import ReminderRuleCreate from '@/components/ReminderRuleCreate'

export default {
  name: 'ReminderRuleList',
  components: {
    Loading,
    Modal,
    ReminderRuleCreate
  },
  props: {
    packId: {
      type: String,
      required: true
    },
    templates: {
      type: Array,
      required: false,
      default: null
    }
  },
  data () {
    return {
      rules: null,
      modalOpen: false,
      deleteLoading: false
    }
  },
  computed: {
    templateById () {
      const res = {}
      for (const t of this.templates) {
        res[t.uuid] = t
      }
      return res
    }
  },
  async mounted () {
    this.rules = await API.getReminderRules(this.packId)
  },
  methods: {
    created (rule) {
      this.modalOpen = false
      this.rules.push(rule)
    },
    ruleString (rule) {
      const vars = {
        delayDay: rule.delayHour / 24,
        templateName: this.templateById[rule.reminderTemplateId].name,
        learningStatus: rule.learningStatus
          .map(s => `"${translateLearningStatus(k => this.$t(k), s)}"`)
          .join(` ${this.$t('or')} `)
      }
      let ruleString = this.$t('ruleString')
      for (const v in vars) {
        ruleString = ruleString.replace(new RegExp(`{{\\s?${v}\\s?}}`), vars[v])
      }
      return ruleString
    },
    async deleteRule (rule) {
      try {
        await this.$dialog.confirm(this.$t('confirmReminderRuleDeletion'), {
          okText: this.$t('confirmOk'),
          cancelText: this.$t('confirmCancel')
        })
      } catch (e) {
        return
      }
      this.deleteLoading = true
      try {
        await API.deleteReminderRule(rule.id)
      } catch (e) {
        console.error(e)
        Sentry.captureException(e, { tag: 'apiResponse', route: 'delete-reminder-rule' })
        this.$toast.error(`Error: ${e}`)
        return // do not update this.rules
      } finally {
        this.deleteLoading = false
      }
      this.rules = this.rules.filter(r => r.id !== rule.id)
    }
  }
}
</script>

<style scoped>
.template-table {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.name {
  width: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.actions > * {
  margin: 0;
}
</style>
