<template>
  <div class="language-selector">
    <button
      v-for="l of locales"
      :key="l"
      class="select-language"
      :class="value === l ? 'active' : undefined"
      @click="() => $emit('input', l)"
    >
      {{ localeName(l) }}
    </button>
  </div>
</template>

<script>
import langs from 'langs'

export default {
  name: 'LanguageField',
  props: {
    value: {
      type: String,
      required: true
    },
    locales: {
      type: Array,
      required: true
    }
  },
  methods: {
    localeName (locale) {
      const toName = (c) => langs.where('1', c.split('-')[0]).name
      return `${toName(locale)} (${locale})`
    }
  }
}
</script>

<style scoped>
.language-selector {
  display: flex;
  margin: 0 auto;
  overflow-x: scroll;
  margin: 10px 0px;
  /* for scroll bar */
  padding-bottom: 20px;
  white-space: nowrap;
}

/* https://bhch.github.io/posts/2021/04/centring-flex-items-and-allowing-overflow-scroll/ */
.language-selector :first-child {
  margin-left: auto;
}

.language-selector :last-child {
  margin-right: auto;
}

.select-language {
  border: 0;
  background-color: #cccccc;
  cursor: pointer;
  padding: 5px 20px;
  line-height: 1;
  margin: 0 5px;
}

.select-language.active {
  background-color: rgba(233,94,56,1);
  color: white;
}
</style>
