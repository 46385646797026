<template>
  <div>
    <Loading v-if="packs === null" />
    <div v-else-if="packs.length === 0">
      {{ $t('errorNoLearnings') }}
    </div>
    <template v-else>
      <SubscribeUsers
        v-show="openImport"
        :subscribe-users="inviteMultiLearning"
        :multi-learning="packs.map(p => ({ ...p, thumbnail: thumbnails[p.datoThemeId] }))"
        @success="openImport = false"
        @close="openImport = false"
      />
      <div class="center-container pack-list">
        <Pack
          v-for="pack of packs"
          :key="pack.uuid"
          :pack="pack"
          :thumbnail="thumbnails[pack.datoThemeId] ? thumbnails[pack.datoThemeId].url: ''"
          @deleted="$emit('deleted', $event)"
        />
      </div>
      <div class="center-container">
        <div class="button-container">
          <button
            v-if="packs.length > 1"
            class="nostyle action-container"
            @click="openImport = true"
          >
            <div class="button rounded yellow">
              <img
                class="btn-img"
                src="@/assets/icon-upload.svg"
                alt=""
              >
            </div>
            <p class="btn-title">
              {{ $t('importMultiLearningTitle') }}
            </p>
            <p class="btn-text">
              {{ $t('importMultiLearningSubtitle') }}
            </p>
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { API } from '@/API'
import Loading from '@/components/Loading'
import SubscribeUsers from '@/components/subscribe-users/SubscribeUsers'
import Pack from './Pack'
import GetThemesThumbnail from '@/graphql/GetThemesThumbnail.gql'

export default {
  name: 'PackList',
  components: {
    Loading,
    Pack,
    SubscribeUsers
  },
  props: {
    packs: {
      type: Array,
      default: null
    },
    clientId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      allThemes: [],
      openImport: false
    }
  },
  apollo: {
    allThemes: {
      query: GetThemesThumbnail,
      variables () {
        return {
          locale: this.$i18n.locale
        }
      }
    }
  },
  computed: {
    thumbnails () {
      if (!this.allThemes) {
        return {}
      }
      const ret = this.allThemes.reduce((obj, item) => {
        if (item.id && item.thumbnail.url) {
          obj[item.id] = item.thumbnail
        }
        return obj
      }, {})
      return ret
    }
  },
  methods: {
    async inviteMultiLearning (users, packIds) {
      await API.inviteMultiLearning(this.clientId, packIds, users)
    }
  }
}
</script>

<style scoped>
.button-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.pack-list {
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: calc(100% / 2 - (20px * 2));
    margin: 20px;
  }
}
</style>
