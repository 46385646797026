<template>
  <Loading v-if="apiLoading || !defaultEmail" />
  <div v-else class="container">
    <slot name="intro" />
    <EmailSend
      v-model="email"
      class="content"
      :default-email="defaultEmail"
      :locales="locales"
      :send-email="sendDiploma"
      :ready-to-send="usersMap !== null && progress !== null"
      settings-router-name="pack-settings-diploma"
    />
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import GetDefaultDiplomaEmail from '@/graphql/GetDefaultDiplomaEmail.gql'
import EmailSend from '@/components/emails/EmailSend'
import { mapState } from 'vuex'
import { API } from '@/API'

import * as Sentry from '@sentry/vue'

export default {
  name: 'EmailFormCertificate',
  components: {
    Loading,
    EmailSend
  },
  props: {
    pack: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: true
    },
    progress: {
      type: Array,
      required: false,
      default: null
    },
    usersMap: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      apiLoading: true
    }
  },
  computed: {
    ...mapState([
      'datoLocales'
    ]),
    locales () {
      return this.client.languages.length === 0
        ? this.datoLocales
        : this.client.languages
    }
  },
  apollo: {
    defaultEmail: {
      query: GetDefaultDiplomaEmail
    }
  },
  async mounted () {
    API.getDiplomaEmail(this.pack.uuid)
      .then(email => { this.email = email })
      .catch(() => { this.email = null })
      .finally(() => { this.apiLoading = false })
  },
  methods: {
    async sendDiploma () {
      const diplomaUsers = this.progress
        .filter(p => {
          if (p.status !== 'completed') { return false }
          if (p.diplomaSent) { return false }
          // We need user language to send the diploma
          // Users can register on a module if the module has loginMode === 'email'
          // Then, the module can be changed to loginMode === 'token' but old users does not have a language
          const user = this.usersMap[p.userUUID]
          if (!user.language || !user.firstName || !user.lastName) { return false }
          return true
        })
      if (diplomaUsers.length === 0) {
        this.$toast.success(`0 ${this.$t('userInvited')}`)
        return
      }
      return API.sendDiploma(this.pack.uuid, diplomaUsers.map(p => p.userUUID)).then(() => {
        diplomaUsers.forEach(p => { p.diplomaSent = true })
        this.$emit('close')
        this.$toast.success(
        `${diplomaUsers.length} ` + diplomaUsers.length > 1
          ? this.$t('usersInvited')
          : this.$t('userInvited')
        )
      })
        .catch(err => {
          Sentry.captureException(err, { tag: 'apiResponse', route: 'send-dipoma' })
          this.$toast.error(`Error: ${err}`)
        })

      // Send diploma to users who validated the learning
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.content {
    margin-top: 40px;
  }

.inline-field-container {
  display: flex;

  & :first-child {
    margin-right: 10px;
  }

  & :last-child {
    width: 100%;
  }
}
</style>
