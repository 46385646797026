<template>
  <LearningLayout @theme="theme= $event" @pack="pack = $event">
    <Loading v-if="!theme || !settings || apiLoading" />
    <div v-else class="center-container">
      <LearningMenu :theme="theme" />
      <LearningSettingsMenu :theme="theme" />
      <EmailFormCertificate
        v-if="client && pack"
        :client="client"
        :pack="pack"
        :users-map="usersMap"
        :progress="progress"
      >
        <template slot="intro">
          <form>
            <div class="field inline-field-container">
              <input id="auto-send-diploma" v-model="settings.autoSendDiploma" type="checkbox">
              <label for="auto-send-diploma">{{ $t('autoSendDiploma') }}</label>
            </div>
          </form>
          <h3>{{ $t('titleDiplomaTemplate') }}</h3>
          <p>{{ $t('introDiplomaTemplate') }}</p>
        </template>
      </EmailFormCertificate>
    </div>
  </LearningLayout>
</template>

<script>
import LearningLayout from '@/components/LearningLayout'
import LearningMenu from '@/components/LearningMenu'
import LearningSettingsMenu from '@/components/LearningSettingsMenu'

import Loading from '@/components/Loading'
import EmailFormCertificate from '@/components/emails/EmailFormCertificate'
import { API } from '@/API'

import * as Sentry from '@sentry/vue'

export default {
  name: 'LearningSettingsInvite',
  components: {
    Loading,
    LearningLayout,
    LearningSettingsMenu,
    LearningMenu,
    EmailFormCertificate
  },
  data () {
    return {
      settings: null,
      theme: null,
      pack: null,
      client: null,
      progress: null,
      users: null,
      apiLoading: true
    }
  },
  computed: {
    usersMap () {
      if (!this.users) {
        return null
      }
      const map = {}
      for (const user of this.users) {
        map[user.uuid] = user
      }
      return map
    }
  },
  watch: {
    settings: {
      async handler () {
        if (this.apiLoading) {
          return
        }
        await API.updatePackSettings(this.pack.uuid, this.settings)
        this.$toast.success(this.$t('settingsUpdated'))
      },
      deep: true
    }
  },
  async mounted () {
    this.pack = await API.getPack(this.$route.params.packId)
    return Promise.all([
      API.getClient(this.pack.clientUUID)
        .then((client) => { this.client = client })
        .catch(err => {
          Sentry.captureException(err, { tag: 'apiResponse', route: 'get-client' })
          throw err
        }),
      API.getPackSettings(this.pack.uuid)
        .then((settings) => { this.settings = settings })
        .catch(err => {
          Sentry.captureException(err, { tag: 'apiResponse', route: 'get-pack-settings' })
          throw err
        }),
      API.getUsers(this.pack.uuid)
        .then((users) => { this.users = users })
        .catch(err => {
          Sentry.captureException(err, { tag: 'apiResponse', route: 'get-users' })
          throw err
        })

    ]).then(() => {
      this.apiLoading = false
      // load progress after as it's slow
      API.getPackProgress(this.pack.uuid)
        .then(progress => { this.progress = progress })
        .catch(err => {
          Sentry.captureException(err, { tag: 'apiResponse', route: 'get-client' })
        })
    })
  },
  methods: {
    async getClient (clientId) {
      API.getClient(clientId)
        .then(client => { this.client = client })
        .catch(err => {
          Sentry.captureException(err, { tag: 'apiResponse', route: 'get-client' })
        })
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.inline-field-container {
  display: flex;

  & :first-child {
    margin-right: 10px;
  }

  & :last-child {
    width: 100%;
  }
}
</style>
