<template>
  <div
    v-if="pageCount !== 1"
    class="pagination"
  >
    <Paginate
      :value="value"
      :page-count="pageCount"
      :page-range="3"
      :next-text="arrows.next"
      :prev-text="arrows.prev"
      :first-last-button="true"
      :first-button-text="arrows.first"
      :last-button-text="arrows.last"
      next-class="next"
      prev-class="prev"
      :page-class="'page-item'"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate/src/components/Paginate.vue'

export default {
  name: 'Pagination',
  components: {
    Paginate
  },
  props: {
    value: {
      type: Number,
      required: true
    },
    pageCount: {
      type: Number,
      required: true
    }
  },
  computed: {
    arrows () {
      // Declare HTML-encoded entity in script to avoid
      // a HTML minification issue during generation
      return {
        last: '&gt;&gt;',
        first: '&lt;&lt;',
        next: '&gt;',
        prev: '&lt;'
      }
    }
  }
}
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;

  & /deep/ ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
      color: #6b6b6b !important;
      font-size: 20px;
      margin: 5px;

      /* Go to first, go to last buttons */
      &:first-child, &:last-child {
        font-size: 25px;

        &.disabled {
          visibility: hidden;
        }
      }

      &.next,
      &.prev {
        font-size: 25px;

        &.disabled {
          visibility: hidden;
        }
      }

      &.prev {
        margin-right: 15px;
      }

      &.next {
        margin-left: 16px;
      }

      &.active {
        color: rgb(44, 62, 80) !important;
        font-weight: bold;
      }
    }
  }
}
</style>
