<template>
  <div>
    <Header
      :bg-color="theme ? theme.courseColor.hex : null"
      :title-logo="theme ? theme.logo.url : null"
    />
    <router-link
      v-if="pack"
      class="nav-btn"
      :style="{ color: theme ? theme.courseColor.hex: null }"
      :to="prevURL"
    >
      &#9664; {{ pack.name }}
    </router-link>
    <div class="center-container">
      <Loading v-if="!users || !module" />
      <ModuleDetail
        v-else
        :module="module"
        :users-map="usersMap"
      />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import GetThemeById from '@/graphql/GetThemeById.gql'
import ModuleDetail from '@/components/pack-detail/ModuleDetail'
import Loading from '@/components/Loading'
import { API } from '@/API'

export default {
  name: 'ModuleDetailView',
  components: {
    Header,
    Loading,
    ModuleDetail
  },
  data () {
    return {
      users: null,
      module: null,
      pack: null
    }
  },
  apollo: {
    theme: {
      query: GetThemeById,
      skip () {
        return !this.pack
      },
      variables () {
        return {
          id: this.pack.datoThemeId,
          locale: this.$i18n.locale
        }
      }
    }
  },
  computed: {
    usersMap () {
      if (!this.users) {
        return null
      }
      const map = {}
      for (const user of this.users) {
        map[user.uuid] = user
      }
      return map
    },
    prevURL () {
      if (!this.module) {
        return '/'
      }
      return { name: 'pack-detail', params: { packId: this.module.packUUID } }
    }
  },
  async mounted () {
    this.module = await API.getModule(this.$route.params.moduleId)
    this.pack = await API.getPack(this.module.packUUID)
    this.users = await API.getUsers(this.module.packUUID)
  }
}
</script>

<style scoped>
.center-container {
  padding-bottom: 20px;
}
</style>
