<template>
  <div>
    <h3>{{ $t('createAccountTitle') }}</h3>
    <p>{{ $t('createAccountDescription') }}</p>
    <form
      class="create-account"
      @submit.prevent
      @submit="submit"
    >
      <input
        v-model="email"
        type="text"
        class="input-width"
        :placeholder="$t('adminAccessesEmailPlaceholder')"
        required
      >
      <select
        v-model="lang"
        class="input-width"
        required
      >
        <option
          value=""
          disabled
          selected
        >
          Lang
        </option>
        <option value="fr">
          French
        </option>
        <option value="en">
          English
        </option>
      </select>
      <select
        v-model="role"
        class="input-width"
        required
      >
        <option
          value=""
          disabled
          selected
        >
          Role
        </option>
        <option value="access">
          Client
        </option>
        <option value="admin">
          Admin
        </option>
      </select>
      <select
        v-if="role === 'access'"
        v-model="clientID"
        class="input-width"
        required
      >
        <option
          value=""
          disabled
          selected
        >
          {{ $t('adminAccessesClientPlaceholder') }}
        </option>
        <option
          v-for="c of clients"
          :key="c.uuid"
          :value="c.uuid"
        >
          {{ c.name }}
        </option>
      </select>
      <div class="manual-password-checkbox input-width">
        <input
          id="manual-password"
          v-model="manualPassword"
          type="checkbox"
        >
        <label for="manual-password">{{ $t('createAccountManualPassword') }}</label>
      </div>
      <PasswordInput
        v-if="manualPassword"
        v-model="password"
        class="input-width"
        @valid="validPassword = $event"
      />
      <input
        class="button rounded black input-width"
        :disabled="disabled"
        type="submit"
        :value="$t('adminAccessesSubmitPlaceholder')"
      >
    </form>
    <p
      v-if="successAdd"
      class="success"
    >
      Account created!
    </p>
    <p
      v-else-if="successInvite"
      class="success"
    >
      Account created! The user received an email with a link valid for 7 days.
    </p>
    <p
      v-else
      class="error"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import { API } from '@/API'
import PasswordInput from '@/components/PasswordInput'

export default {
  name: 'CreateAccount',
  components: {
    PasswordInput
  },
  data () {
    return {
      clients: [],
      email: '',
      lang: '',
      password: '',
      clientID: '',
      role: '',
      isRequesting: false,
      error: '',
      successInvite: false,
      successAdd: false,
      manualPassword: false
    }
  },
  computed: {
    disabled () {
      if (this.isRequesting) {
        return true
      }
      if (this.manualPassword && !this.password) {
        return true
      }
      if (!this.role) {
        return true
      }
      if (this.role === 'access' && !this.clientID) {
        return true
      }
      if (!this.email) {
        return true
      }
      return false
    }
  },
  async mounted () {
    this.clients = await API.getClients()
  },
  methods: {
    async submit () {
      // Ask confirmation when creating an admin role
      if (this.role === 'admin') {
        try {
          await this.$dialog.confirm(this.$t('confirmAdminCreation'), {
            verificationHelp: this.$t('confirmHelp'),
            okText: this.$t('confirmOk'),
            cancelText: this.$t('confirmCancel')
          })
        } catch (e) {
          return
        }
      }
      if (this.manualPassword) {
        return this.addAccount()
      } else {
        return this.inviteAccount()
      }
    },
    async addAccount () {
      this.successInvite = false
      this.successAdd = false
      this.error = ''
      this.isRequesting = true
      const account = {
        email: this.email,
        password: this.password,
        clientID: this.clientID,
        role: this.role,
        lang: this.lang
      }
      try {
        const { uuid } = await API.addAccount(account)
        this.resetForm()
        this.successAdd = true
        this.$emit('new-account', {
          uuid,
          email: account.email,
          clientUUID: account.clientID,
          role: account.role,
          lastConnection: { Valid: false },
          active: true,
          invitationExpired: undefined,
          lang: account.lang
        })
      } catch (e) {
        if (e.response) {
          this.error = `${e.response.status}: ${JSON.stringify(e.response.data)}`
        } else {
          throw e
        }
      }
      this.isRequesting = false
    },
    // Invite user sends an email inviting user to set their account
    async inviteAccount () {
      this.successInvite = false
      this.successAdd = false
      this.error = ''
      this.isRequesting = true
      const account = {
        email: this.email,
        clientID: this.clientID,
        role: this.role,
        lang: this.lang
      }
      try {
        const { uuid } = await API.inviteAccount(account)
        this.resetForm()
        this.successInvite = true
        this.$emit('new-account', {
          uuid,
          email: account.email,
          clientUUID: account.clientID,
          role: account.role,
          lastConnection: { Valid: false },
          active: false,
          invitationExpired: false,
          lang: account.lang
        })
      } catch (e) {
        if (e.response) {
          this.error = `${e.response.status}: ${JSON.stringify(e.response.data)}`
        } else {
          throw e
        }
      }
      this.isRequesting = false
    },
    resetForm () {
      this.email = ''
      this.password = ''
      this.clientID = ''
      this.role = ''
    }
  }
}
</script>

<style scoped>
.input-width {
   box-sizing: border-box;
   width: calc(100% / 3);
   margin-left: auto;
   margin-right: auto;
   display: block;
}

.create-access {
  display: flex;
  margin-bottom: 20px;

  & input, & select {
    margin: 0;

    &:not(:last-child) {
      border-right: 0;
    }
  }

  & input[type="submit"] {
    margin: 0;
    margin-left: 10px;
  }
}

.error {
  color: red;
}
</style>
