<template>
  <div>
    <Header />
    <div class="center-container">
      <h2 class="page-title">
        {{ $t('updateEmail') }}
      </h2>
      <form
        class="update-account"
        @submit.prevent="updateEmail"
      >
        <input
          v-model="email"
          type="email"
          :placeholder="$t('email')"
          required
        >
        <button
          class="button yellow"
          :disabled="!email || updateEmailLoading"
          type="submit"
        >
          {{ updateEmailLoading ? $t('loading') : $t('update') }}
        </button>
        <div v-if="updateEmailSuccess">
          {{ $t('updateEmailSuccess') }}
        </div>
        <div
          v-if="updateEmailErr"
          class="error"
        >
          {{ updateEmailErr }}
        </div>
      </form>
      <h2 class="page-title">
        {{ $t('updatePassword') }}
      </h2>
      <form
        class="update-account"
        @submit.prevent="updatePassword"
      >
        <PasswordInput
          v-model="password"
          :user-inputs="[ $store.state.account.email ]"
          @valid="validPassword = $event"
        />
        <button
          class="button yellow"
          :disabled="!password || !validPassword || updatePasswdLoading"
          type="submit"
        >
          {{ updatePasswdLoading ? $t('loading') : $t('update') }}
        </button>
        <div v-if="updatePasswdSuccess">
          {{ $t('updatePasswordSuccess') }}
        </div>
        <div
          v-if="updatePasswdErr"
          class="error"
        >
          {{ updatePasswdErr }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import PasswordInput from '@/components/PasswordInput'
import { API, formatAPIErr } from '@/API'

export default {
  name: 'Account',
  components: {
    Header,
    PasswordInput
  },
  data () {
    return {
      email: '',
      password: '',
      validPassword: false,

      updateEmailSuccess: false,
      updateEmailErr: '',
      updateEmailLoading: false,
      updatePasswdSuccess: false,
      updatePasswdErr: '',
      updatePasswdLoading: false
    }
  },
  computed: {
    isValid () {
      return this.email || this.password
    },
    accountId () {
      return this.$store.state.account.uuid
    }
  },
  watch: {
    email () {
      this.updateEmailSuccess = false
    },
    password () {
      this.updatePasswdSuccess = false
    }
  },
  mounted () {
    if (this.$store.state.account) {
      this.email = this.$store.state.account.email
    }
  },
  methods: {
    async updateEmail () {
      this.updateEmailErr = ''
      this.updateEmailSuccess = false
      this.updateEmailLoading = true
      try {
        await API.updateAccount(this.accountId, { email: this.email })
        this.updateEmailSuccess = true
      } catch (e) {
        this.updateEmailErr = formatAPIErr(e)
      } finally {
        this.updateEmailLoading = false
      }
    },
    async updatePassword () {
      this.updatePasswdErr = ''
      this.updatePasswdSuccess = false
      this.updatePasswdLoading = true
      try {
        await API.updateAccount(this.accountId, { password: this.password })
        this.updatePasswdSuccess = true
      } catch (e) {
        this.updatePasswdErr = formatAPIErr(e)
      } finally {
        this.updatePasswdLoading = false
      }
    }
  }
}
</script>

<style scoped>
.update-account > input {
  width: 400px;
}

.error {
  color: red;
}
</style>
