<template>
  <LearningLayout @theme="theme= $event" @pack="pack = $event">
    <Loading v-if="loading || !theme" />
    <div v-else class="center-container">
      <LearningMenu :theme="theme" />
      <LearningSettingsMenu :theme="theme" />
      <div class="container">
        <div>
          <router-link
            class="close-btn"
            :to="{
              name: 'pack-settings-reminder',
              params: { packId: $route.params.packId }
            }"
          >
            &times;
          </router-link>
          <h3 class="inline-title">{{ $t('updateReminderTemplate') }}</h3>
        </div>
        <LanguageField v-model="selectedLocale" :locales="locales" />
        <CustomEmailForm
          :email="email"
          :locales="locales"
          :selected-locale="selectedLocale"
          :loading="updateLoading"
          :error="error"
          :has-name="true"
          @submit="update"
        />
      </div>
    </div>
  </LearningLayout>
</template>

<script>
import Loading from '@/components/Loading'
import LearningLayout from '@/components/LearningLayout'
import LearningMenu from '@/components/LearningMenu'
import LearningSettingsMenu from '@/components/LearningSettingsMenu'
import CustomEmailForm from '@/components/CustomEmailForm'
import LanguageField from '@/components/LanguageField'

import * as Sentry from '@sentry/vue'
import { mapState } from 'vuex'
import { API, formatAPIErr } from '@/API'

export default {
  name: 'LearningSettingsReminderUpdate',
  components: {
    Loading,
    LearningLayout,
    LearningMenu,
    LearningSettingsMenu,
    LanguageField,
    CustomEmailForm
  },
  data () {
    return {
      loading: true,
      updateLoading: false,
      theme: null,
      form: {},
      selectedLocale: '',
      error: ''
    }
  },
  computed: {
    ...mapState([
      'datoLocales'
    ]),
    locales () {
      if (this.client.languages.length === 0) {
        return this.datoLocales
      }
      return this.client.languages
    }
  },
  async mounted () {
    await Promise.all([
      (async () => { this.email = await API.getReminderTemplate(this.$route.params.templateId) })(),
      (async () => {
        this.pack = await API.getPack(this.$route.params.packId)
        this.client = await API.getClient(this.pack.clientUUID)
      })()
    ])
    this.selectedLocale = this.locales[0]
    this.loading = false
  },
  methods: {
    async update (body) {
      this.error = ''
      this.updateLoading = true
      try {
        await API.updateReminderTemplate(this.$route.params.templateId, {
          ...body,
          packUuid: this.pack.uuid
        })
        this.$toast.success(this.$t('emailSaveSuccess'))
      } catch (e) {
        Sentry.captureException(e, { tag: 'apiResponse', route: 'update-reminder-email' })
        this.error = formatAPIErr(e)
        return
      } finally {
        this.updateLoading = false
      }

      this.$router.push({
        name: 'pack-settings-reminder',
        params: { packId: this.$route.params.packId }
      })
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.inline-title {
  display: inline-block;
  margin-left: 10px;
}
</style>
