<template>
  <div class="preview-container">
    <table
      class="columns"
      cellspacing="0"
    >
      <thead>
        <tr>
          <th>{{ $t('email') }}</th>
          <th>{{ $t('firstName') }}</th>
          <th>{{ $t('lastName') }}</th>
          <th>{{ $t('language') }}</th>
          <th>{{ $t('timezone') }}</th>
          <th v-if="oneHasBranch">{{ $t('branch') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="i in realMax"
          :key="`user-preview-${i}`"
        >
          <td>{{ users[i - 1].email }}</td>
          <td>{{ users[i - 1].firstName }}</td>
          <td>{{ users[i - 1].lastName }}</td>
          <td>{{ users[i - 1].language }}</td>
          <td>{{ users[i - 1].timezone }}</td>
          <td v-if="oneHasBranch">{{ users[i - 1].branch }}</td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="hasRemaining"
      class="remaining"
    >
      {{ $t('usersRemaining').replace(/\{\{\s?count\s?\}\}/, remaining) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preview',
  props: {
    users: {
      type: Array,
      required: true
    },
    maxPreview: {
      type: Number,
      default: 5
    }
  },
  computed: {
    realMax () {
      if (this.users.length > this.maxPreview) {
        return this.maxPreview
      }
      return this.users.length
    },
    hasRemaining () {
      return this.users.length > this.maxPreview
    },
    remaining () {
      return this.users.length - this.maxPreview
    },
    oneHasBranch () {
      return this.users.some(u => typeof u.branch === 'string' && u.branch !== '')
    }
  }
}
</script>

<style scoped>
.columns {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0;

  & td {
    border: solid 1px #000;
  }

  & td:not(:last-child) {
    border-right: 0;
  }

  & tr:not(:last-child) td {
    border-bottom: 0;
  }
}

.remaining {
  border: 1px solid black;
  border-top: none;
  padding: 5px;
  box-sizing: border-box;
}

.preview-container {
  margin-bottom: 10px;
}
</style>
