<template>
  <!-- It is safe to disable this rule because the html cannot be unsafe -->
  <!-- eslint-disable-next-line vue/no-v-html -->
  <span v-html="getTime(time)" />
</template>

<script>
export default {
  name: 'ElapsedTime',
  props: {
    time: {
      type: Number,
      required: true
    }
  },
  methods: {
    getTime (r) {
      const totalS = this.time
      const minutes = Math.floor(totalS / 60)
      const seconds = totalS - minutes * 60
      return `${minutes}&nbsp;minutes ${seconds}&nbsp;seconds`
    }
  }
}
</script>
