<template>
  <ClientLayout class="learner-list">
    <div class="center-container">
      <Loading v-if="!users" />
      <template v-else>
        <div class="header">
          <input v-model="search" type="text" :placeholder="$t('searchUsersPlaceholder')">
        </div>
        <table class="learner-table">
          <thead>
            <tr>
              <th>{{ $t('email') }}</th>
              <template v-if="userHasName">
                <th>{{ $t('name') }}</th>
              </template>
              <th>{{ $t('language') }}</th>
              <template v-if="userHasBranch">
                <th>{{ $t('branch') }}</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="u of paginatedUsers" :key="u.uuid">
              <td>
                <router-link class="learner-link" :to="{ name: 'learner-detail', params: { learnerId: u.uuid } }">
                  {{ u.email }}
                </router-link>
              </td>
              <template v-if="userHasName">
                <td>
                  {{ u.firstName }} {{ u.lastName }}
                </td>
              </template>
              <td>{{ languageText(u.language) }}</td>
              <template v-if="userHasBranch">
                <td>
                  {{ u.branch }}
                </td>
              </template>
            </tr>
          </tbody>
        </table>
        <div class="footer">
          {{ filteredUsers.length }} users
        </div>
        <Pagination v-model="currentPage" :page-count="pageCount" />
      </template>
    </div>
  </ClientLayout>
</template>

<script>
import langs from 'langs'
import { API } from '@/API'
import ClientLayout from '@/components/ClientLayout'
import Loading from '@/components/Loading'
import Pagination from '@/components/Pagination'

export default {
  name: 'LearnerList',
  components: {
    ClientLayout,
    Loading,
    Pagination
  },
  data () {
    return {
      currentPage: 1,
      users: null,
      err: null,
      search: ''
    }
  },
  computed: {
    clientId () {
      return this.$route.params.clientId || this.$store.state.clientUUID
    },
    usersMap () {
      if (!this.users) {
        return null
      }
      const map = {}
      for (const user of this.users) {
        map[user.uuid] = user
      }
      return map
    },
    filteredUsers () {
      if (!this.users) {
        return []
      }
      return this.users.filter(u => {
        const fields = [u.email, u.firstName, u.lastName]
        // Remove special characters to improve search
        const clean = input => input.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        return fields.some(f => clean(f).includes(clean(this.search)))
      })
    },
    userHasBranch () {
      if (!this.usersMap) {
        return undefined
      }
      const user = this.users.find(u => u.branch !== null)
      return user !== undefined
    },
    userHasName () {
      if (!this.usersMap) {
        return undefined
      }
      const user = this.users.find(u => u.firstName !== '' || u.lastName !== '')
      return user !== undefined
    },
    pageLength () {
      return 30
    },
    pageCount () {
      return Math.ceil(this.filteredUsers.length / this.pageLength)
    },
    paginatedUsers () {
      const offset = (this.currentPage - 1) * this.pageLength
      return this.filteredUsers.slice(offset, offset + this.pageLength)
    }
  },
  async mounted () {
    try {
      this.users = await API.getClientUsers(this.clientId)
    } catch (e) {
      this.err = e
    }
  },
  methods: {
    languageText (lang) {
      const found = langs.where('1', lang)
      return found?.local
    }
  }
}
</script>

<style scoped>
.learner-table {
  text-align: left;
  width: 100%;
}

.header {
  text-align: left;
  margin-bottom: 30px;

  & input[type="text"] {
    margin: 0;
  }
}

.footer {
  text-align: right;
}

.learner-link {
  color: inherit;
}
</style>
