<template>
  <div>
    <Password
      ref="field"
      v-model="password"
      type="password"
      :placeholder="$t('loginPasswordPlaceholder')"
      :badge="false"
      :toggle="true"
      :user-inputs="userInputs"
      :reference-value="input"
      @score="score = $event"
    />
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter'

export default {
  name: 'PasswordInput',
  components: {
    Password
  },
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    userInputs: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      password: '',
      score: 0,
      input: null
    }
  },
  watch: {
    password () {
      this.$emit('input', this.password)
    },
    score () {
      this.$emit('valid', this.score >= 3)
    }
  },
  created () {
    this.password = this.value
  },
  mounted () {
    const input = this.$refs.field.$el.querySelector('input')
    if (!input) {
      console.error('input not found')
      return
    }
    input.addEventListener('change', this.customValidity)
  },
  beforeUnmount () {
    const input = this.$refs.field.$el.querySelector('input')
    if (!input) {
      console.error('input not found')
      return
    }
    input.removeEventListener('change', this.customValidity)
  },
  methods: {
    customValidity () {
      const input = this.$refs.field.$el.querySelector('input')
      if (!input) {
        return
      }
      if (this.score < 3) {
        input.setCustomValidity(this.$t('passwordTooWeak'))
      } else {
        input.setCustomValidity('')
      }
    }
  }
}
</script>

<style scoped>
</style>
