import Vue from 'vue'
import VueI18n from 'vue-i18n'

import GetLanguages from '@/graphql/GetLanguages.gql'
import GetI18nMessages from '@/graphql/GetI18nMessages.gql'

async function getLanguages (client) {
  const q = await client.query({
    query: GetLanguages
  })
  return q.data.recordsUi.availableLanguages
}

async function getMessages (client, locale) {
  const q = await client.query({
    query: GetI18nMessages,
    variables: {
      locale
    }
  })
  return q.data.recordsUi
}

export async function createI18n (apolloProvider) {
  const client = apolloProvider.defaultClient
  const langs = await getLanguages(client)

  const messages = {}
  for (const lang of langs) {
    messages[lang.code] = await getMessages(client, lang.code)
  }

  function initialLang () {
    const lc = localStorage.getItem('lang')
    if (lc) {
      return lc
    }
    let raw
    const urlParams = new URLSearchParams(window.location.search)
    const query = urlParams.get('lang')
    if (query) {
      // Search in query params
      raw = query
    } else {
      // Or browser
      raw = navigator.language
    }
    return raw.split('-')[0]
  }

  return new VueI18n({
    locale: initialLang(),
    fallbackLocale: 'en',
    messages
  })
}

Vue.use(VueI18n)
