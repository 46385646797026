<template>
  <div class="timezone-input">
    <select :value="selectedCountry" @change="setCountry">
      <option
        disabled
        value=""
      >
        {{ $t('country') }}
      </option>
      <option
        v-for="c of countries"
        :key="c.id"
        :value="c.id"
      >
        {{ c.name }}
      </option>
      <option value="Etc">
        Etc
      </option>
    </select>
    <select :value="value" required @change="setTz($event.target.value)">
      <option
        disabled
        value=""
      >
        {{ $t('timezone') }}
      </option>
      <option
        v-for="t of timezones"
        :key="t"
        :value="t"
      >
        {{ t }}
      </option>
    </select>
  </div>
</template>

<script>
import ct from 'countries-and-timezones'

export default {
  name: 'TimezoneInput',
  props: {
    value: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data () {
    return {
      selectedCountry: ''
    }
  },
  computed: {
    countries () {
      return ct.getAllCountries()
    },
    timezones () {
      if (this.selectedCountry === '') {
        return Object.keys(ct.getAllTimezones())
      }
      if (this.selectedCountry === 'Etc') {
        return ['Etc/UTC']
      }
      return ct.getTimezonesForCountry(this.selectedCountry).map(t => t.name)
    }
  },
  created () {
    this.$parent.$on('reset-form', this.resetForm)
  },
  beforeDestroy () {
    this.$parent.$off('reset-form', this.resetForm)
  },
  mounted () {
    const tz = this.value || this.getBrowserTz()
    this.selectedCountry = this.getCountryFromTz(tz)
    this.setTz(tz)
  },
  methods: {
    setTz (val) {
      this.$emit('input', val)
    },
    getBrowserTz () {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    getCountryFromTz (tz) {
      if (tz === 'Etc/UTC') {
        return 'Etc'
      }
      const country = ct.getCountriesForTimezone(tz)?.[0]
      if (country) {
        return country.id
      }
      return undefined
    },
    setCountry (ev) {
      const newCountry = ev.target.value
      this.selectedCountry = newCountry
      if (this.timezones.length === 1) {
        // if there is only one timezone for this country, we pre-select the timezone
        this.setTz(this.timezones[0])
      } else {
        // or ask user's choice
        this.setTz('')
      }
    },
    // used to reset form from parent
    resetForm () {
      const tz = this.getBrowserTz()
      this.selectedCountry = this.getCountryFromTz(tz)
      this.setTz(tz)
    }
  }
}
</script>

<style scoped>
.timezone-input > * {
  width: 100%;
  box-sizing: border-box;
}
</style>
