<template>
  <div class="container">
    <div class="mode-select-container">
      <template v-if="!formOpen && value === null">
        <p>ℹ️  {{ $t('infoDefaultEmailTemplate') }}</p>
        <button class="button blue" @click="formOpen = true">{{ $t('customize') }}</button>
      </template>
      <template v-else>
        <p>ℹ️  {{ $t('infoCustomEmailTemplate') }}</p>
        <button class="button blue" @click="doDeleteTemplate">{{ $t('uncustomize') }}</button>
      </template>
    </div>
    <LanguageField v-model="selectedLocale" :locales="locales" />
    <div v-if="!formOpen">
      <div class="field">
        <label class="bold">
          {{ $t('emailSubject') }}
        </label>
        <div class="default-field">
          <DatoI18nText
            :val="defaultEmail.subject"
            :locale="selectedLocale"
          />
        </div>
      </div>
      <div class="field">
        <label class="bold">
          {{ $t('emailContent') }}
        </label>
        <div class="default-field">
          <DatoI18nText
            class="multiline"
            :val="defaultEmail.content"
            :locale="selectedLocale"
          />
        </div>
      </div>
      <template v-if="sendEmail">
        <template v-if="readyToSend">
          <button class="button send btn-m green" @click="sendEmail">
            {{ $t('send') }}
          </button>
        </template>
        <Loading v-else />
      </template>
    </div>
    <CustomEmailForm
      v-else
      :email="value"
      :default-email="defaultEmail"
      :locales="locales"
      :selected-locale="selectedLocale"
      :loading="updateLoading"
      :error="error"
      :send-email="sendEmail"
      @submit="doUpdateTemplate"
    />
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { formatAPIErr } from '@/API'

import Loading from '@/components/Loading'
import DatoI18nText from '@/components/DatoI18nText'
import LanguageField from '@/components/LanguageField'
import CustomEmailForm from '@/components/CustomEmailForm'

export default {
  name: 'EmailForm',
  components: {
    DatoI18nText,
    LanguageField,
    CustomEmailForm,
    Loading
  },
  props: {
    readyToSend: {
      type: Boolean,
      required: false,
      default: true
    },
    defaultEmail: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: false,
      default: null
    },
    locales: {
      type: Array,
      required: true
    },
    updateTemplate: {
      type: Function,
      required: true
    },
    deleteTemplate: {
      type: Function,
      required: true
    },
    sendEmail: {
      type: Function,
      required: false,
      default: null
    }
  },
  data () {
    return {
      selectedLocale: '',
      updateLoading: false,
      error: '',
      formOpen: false
    }
  },
  created () {
    // user has set a custom email
    if (this.value !== null) {
      this.formOpen = true
    }
    this.selectedLocale = this.locales[0]
  },
  methods: {
    async doUpdateTemplate (body) {
      this.error = ''
      this.updateLoading = true
      try {
        await this.updateTemplate(body)
        this.$toast.success(this.$t('emailSaveSuccess'))
      } catch (e) {
        Sentry.captureException(e, { tag: 'apiResponse', route: 'update-email' })
        this.error = formatAPIErr(e)
        return
      } finally {
        this.updateLoading = false
      }

      this.$emit('input', body)
    },
    async doDeleteTemplate () {
      this.error = ''
      try {
        await this.$dialog.confirm(this.$t('confirmRemoveCustomEmail'), {
          okText: this.$t('confirmOk'),
          cancelText: this.$t('confirmCancel')
        })
      } catch (e) {
        return
      }
      if (this.value === null) {
        // email was not saved to API, so no need to call DELETE
        this.formOpen = false
        return
      }
      try {
        await this.deleteTemplate()
      } catch (e) {
        Sentry.captureException(e, { tag: 'apiResponse', route: 'delete-email' })
        this.error = formatAPIErr(e)
        return
      }
      this.$emit('input', null)
      this.formOpen = false
    }
  }
}
</script>

<style scoped>
.mode-select-container {
  display: flex;
  justify-content: space-between;

  & p {
    margin: 0;
  }

  & .button {
    margin: 0;
    font-size: 16px;
    padding-left: 7px;
    padding-right: 7px;
  }

  margin-bottom: 10px;
}

label {
  cursor: pointer;
  margin-bottom: 5px;
}

.multiline {
  white-space: pre-line;
}

.default-field {
  background: #eaeaea;
  border: 1px solid #b3b3b3;
  padding: 7px 10px;

  & label {
    display: block;
    margin-bottom: 5px;
  }
}

.field {
  margin-bottom: 20px;
}

.button.send  {
    display: block;
    margin: 20px auto;
  }
</style>
