<template>
  <div class="container">
    <div
      v-if="selectFormat"
      class="select-format"
    >
      <button
        class="button rounded green"
        @click="startDownload('xlsx')"
      >
        Excel
      </button>
      <button
        class="button rounded yellow"
        @click="startDownload('csv')"
      >
        CSV
      </button>
    </div>
    <button
      class="nostyle action-container"
      :disabled="loading"
      @click="selectFormat = !selectFormat"
    >
      <div
        class="button rounded black"
        :class="{ loading: loading }"
      >
        <span
          v-if="loading"
          class="btn-img"
        >
          {{ $t('loading') }}
        </span>
        <img
          v-else
          class="btn-img"
          src="@/assets/icon-download.svg"
          alt=""
        >
      </div>
      <p class="btn-title">
        {{ $t('recordsDownloadTitle') }}
      </p>
      <p class="btn-text">
        {{ $t('recordsDownloadSubtitle') }}
      </p>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ExportProgress',
  props: {
    download: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      selectFormat: false
    }
  },
  methods: {
    async startDownload (fmt) {
      this.selectFormat = false
      this.loading = true
      try {
        await this.download(fmt)
      } catch (e) {
        this.loading = false
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
.container {
  position: relative;
}
.select-format {
  display: flex;
  position: absolute;
  top: -45px;

  & > :first-child {
    margin-right: 2.5px;
  }
  & > :last-child {
    margin-left: 2.5px;
  }
}
.button.loading {
  padding: 10px 5px 7px;
  max-width: 100%;
  width: 100%;
}
</style>
