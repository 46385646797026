<template>
  <div class="bulk-container">
    <p class="instruction">
      {{ $t('importBulkInstructions') }}
    </p>
    <table class="columns">
      <tbody>
        <tr>
          <td><code>email</code></td>
          <td><code>first_name</code></td>
          <td><code>last_name</code></td>
          <td><code>language</code> {{ $t('languageFormat') }}</td>
          <td><code>timezone</code> {{ $t('timezoneFormat') }}</td>
          <td><code>branch</code> ({{ $t('optional') }})</td>
        </tr>
      </tbody>
    </table>
    <input
      id="select-file"
      ref="fileInput"
      type="file"
      @change="onChange"
    >
    <label
      class="button rounded white select-file"
      for="select-file"
    >
      {{ $t('chooseAFile') }}
    </label>
    <div
      v-if="errors.length > 0"
      class="error"
    >
      <p
        v-for="(err, idx) of errors"
        :key="idx"
      >
        {{ err }}
      </p>
    </div>
    <template v-if="users">
      <p class="file-name">
        {{ fileName }} <b>({{ users.length }} {{ $t('users') }})</b>
      </p>
      <Preview :users="users" />
      <template v-if="multiLearning !== null">
        <p>{{ $t('importUsersInTheFollowingLearning') }}</p>
        <MultiSelectLearning
          class="learning-select"
          :learnings="multiLearning"
          @input="packs = $event"
        />
      </template>
      <div
        v-if="validationError"
        class="error"
      >
        {{ validationError }}
      </div>
      <button
        :disabled="isProcessing"
        class="button rounded black"
        @click="submit"
      >
        <span v-if="!isProcessing">{{ $t('submit') }}</span>
        <span v-else>{{ $t('loading') }}</span>
      </button>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Preview from './Preview'
import MultiSelectLearning from './MultiSelectLearning'
import Papa from 'papaparse'

export default {
  name: 'Bulk',
  components: {
    MultiSelectLearning,
    Preview
  },
  props: {
    isProcessing: {
      type: Boolean,
      required: true
    },
    multiLearning: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      users: null,
      fileName: null,
      errors: [],
      packs: [],
      validationError: ''
    }
  },
  computed: {
    ...mapState([
      'datoLocales'
    ])
  },
  created () {
    this.$parent.$on('success', this.resetData)
  },
  beforeDestroy () {
    this.$parent.$off('success', this.resetData)
  },
  methods: {
    submit () {
      this.validationError = ''
      if (this.multiLearning !== null && this.packs.length === 0) {
        this.validationError = this.$t('errorFormMissingLearning')
        return
      }
      this.$emit('submit', { users: this.users, packs: this.packs })
    },
    resetData () {
      this.users = null
      this.fileName = null
      this.errors = []

      // May not be defined if component is unmounted
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = ''
      }
    },
    onFileReady (fileContent) {
      // https://developer.mozilla.org/en-US/docs/Web/API/USVString
      // Unpaired surrogate codepoints present in USVString are converted
      // by the browser to Unicode 'replacement character' U+FFFD, (�).
      for (let i = 0; i < fileContent.length; i++) {
        if (fileContent.charCodeAt(i) === 0xFFFD) {
          this.errors.push('Oops ! This file is not encoded as UTF-8.')
          return
        }
      }

      // Parse file
      const parserConfig = {
        header: true,
        // Empty lines (without delimiters) and lines with empty content will be skipped
        skipEmptyLines: 'greedy'
      }
      const { data, errors, meta } = Papa.parse(fileContent, parserConfig)

      // Checks
      if (meta.aborted) {
        if (errors.length > 0) {
          console.error(errors)
          this.errors.push(`Invalid CSV file: ${errors[0].message}`)
        } else {
          this.errors.push('Invalid CSV file')
        }
        return
      }
      if (meta.fields.indexOf('email') === -1) {
        this.errors.push('Missing "email" field in CSV file')
        return
      }
      if (meta.fields.indexOf('first_name') === -1) {
        this.errors.push('Missing "first_name" field in CSV file')
        return
      }
      if (meta.fields.indexOf('last_name') === -1) {
        this.errors.push('Missing "last_name" field in CSV file')
        return
      }
      if (meta.fields.indexOf('language') === -1) {
        this.errors.push('Missing "language" field in CSV file')
        return
      }
      if (data.length === 0) {
        this.errors.push('Empty CSV file')
        return
      }

      // Normalize result
      const users = data.map(user => ({
        email: user.email ? user.email.toLowerCase().trim() : '',
        firstName: user.first_name ? user.first_name.trim() : '',
        lastName: user.last_name ? user.last_name.trim() : '',
        language: user.language ? user.language.toLowerCase().trim() : '',
        branch: user.branch ? user.branch.toLowerCase().trim() : undefined,
        timezone: user.timezone ? user.timezone.trim() : 'Etc/UTC'
      }))

      const isValidLang = (lang) => this.datoLocales.indexOf(lang) !== -1

      users
        .filter((u) => !isValidLang(u.language))
        .forEach((invalidLangUser) => {
          this.errors.push(`User (${invalidLangUser.email}) has invalid language: ${invalidLangUser.language}`)
        })

      const isValidTimezone = (tz) => {
        if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
          throw new Error('Time zones are not available in this environment')
        }
        try {
          Intl.DateTimeFormat(undefined, { timeZone: tz })
          return true
        } catch (e) {
          return false
        }
      }

      users.filter(u => !isValidTimezone(u.timezone)).forEach((user) => {
        this.errors.push(`User (${user.email}) has invalid timezone: "${user.timezone}"`)
      })

      const isValidEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email))
      }

      users
        .filter((u) => !isValidEmail(u.email))
        .forEach((invalidEmailUser) => {
          this.errors.push(`User has invalid email: ${invalidEmailUser.email}`)
        })

      users.filter(u => u.firstName === '').forEach(invalidFirstNameUser => {
        this.errors.push(`User has empty first_name: ${invalidFirstNameUser.email}`)
      })
      users.filter(u => u.lastName === '').forEach(invalidLastNameUser => {
        this.errors.push(`User has empty last_name: ${invalidLastNameUser.email}`)
      })

      const findDuplicates = (arr) => {
        const object = {}
        const result = []

        arr.forEach((user) => {
          if (!object[user.email]) { object[user.email] = 0 }
          object[user.email] += 1
        })

        for (const prop in object) {
          if (object[prop] >= 2) {
            result.push(prop)
          }
        }

        return result
      }

      const dupl = findDuplicates(users)
      if (dupl.length > 0) {
        this.errors.push(`The following emails are duplicated : ${dupl.join(', ')}`)
      }

      if (this.errors.length > 0) {
        return
      }

      // Set parsed users to be submitted
      this.users = users
    },
    onChange (evt) {
      // Reset result
      this.errors = []
      this.users = null

      const files = evt.target.files
      const file = files[0]
      if (file === undefined) {
        return
      }
      if (file.name.split('.').pop() !== 'csv') {
        this.errors.push('Please choose a CSV file')
        return
      }
      this.fileName = file.name
      const reader = new FileReader()
      reader.onload = (event) => this.onFileReady(event.target.result)
      reader.onerror = (error) => {
        console.error('Unable to load file')
        console.error(error)
      }
      reader.readAsText(file)
    }
  }
}
</script>

<style scoped>
.bulk-container {
  max-width: 900px;
  margin: 0 auto;
}

.file-name {
  font-weight: bold;
  text-decoration: underline;
}

.instruction {
  margin: 0;
  margin-bottom: 20px;
}

.columns {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  margin-bottom: 10px;

  & td {
    border: 1px solid black;
    padding: 5px 15px;
  }
}

.learning-select {
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 10px;
}

input[type="file"] {
  display: none;

  & + label {
    font-size: 16px;
    margin: 10px auto;
  }
}

.error {
  color: red;
}
</style>
