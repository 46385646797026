<template>
  <div id="admin-dashboard-layout">
    <Header />
    <div class="center-container">
      <DashboardNav class="nav" />
      <slot />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import DashboardNav from '@/components/DashboardNav'

export default {
  name: 'DashboardLayout',
  components: {
    Header,
    DashboardNav
  }
}
</script>

<style scoped>
#admin-dashboard-layout {
  padding-bottom: 20px;
}

.nav {
  margin-bottom: 40px;
}
</style>
