<template>
  <div class="pack">
    <router-link
      :to="{ name: 'pack-detail', params: { packId: pack.uuid } }"
      :title="pack.key"
    >
      <img
        class="thumbnail"
        :src="thumbnail"
        :alt="pack.name"
      >
    </router-link>
    <button
      v-if="$store.getters.isAdmin && deletable"
      class="button red delete-btn"
      :disabled="loadingDelete"
      @click="deletePack"
    >
      {{ loadingDelete ? $t('loading') : $t('delete') }}
    </button>
  </div>
</template>

<script>
import { API } from '@/API'

export default {
  name: 'Pack',
  props: {
    pack: {
      type: Object,
      required: true
    },
    thumbnail: {
      type: String,
      required: false,
      default: null
    },
    deletable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      loadingDelete: false
    }
  },
  methods: {
    async deletePack () {
      try {
        await this.$dialog.confirm(this.$t('confirmPackDeletion'), {
          type: 'hard',
          verification: this.pack.name,
          verificationHelp: this.$t('confirmHelp'),
          okText: this.$t('confirmOk'),
          cancelText: this.$t('confirmCancel')
        })
      } catch (e) {
        return
      }
      this.loadingDelete = true
      try {
        await API.deletePack(this.pack.uuid)
        this.$emit('deleted', this.pack)
      } catch (e) {
        console.error('Unable to delete pack', e)
      } finally {
        this.loadingDelete = false
      }
    }
  }
}
</script>

<style scoped>
.pack {
  display: block;
  position: relative;
  transition: transform 0.1s;

  &:hover {
    transform: scale(1.1);
  }
}

.thumbnail {
  display: block;
}

.delete-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  font-size: 16px;
}
</style>
