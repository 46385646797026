<template>
  <div>
    <Header />
    <h2 class="page-title">
      {{ $t('forgotPassword') }}
    </h2>
    <form
      v-if="!success"
      @submit.prevent="forgotPassword"
    >
      <input
        v-model="email"
        type="email"
        :placeholder="$t('email')"
        required
      >
      <button
        class="button yellow"
        :disabled="!email || loading"
        type="submit"
      >
        {{ loading ? $t('loading') : $t('submit') }}
      </button>
    </form>
    <div v-else>
      {{ $t('forgotPasswordSuccess') }}
    </div>
    <div
      v-if="error"
      class="error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import { API, formatAPIErr } from '@/API'

export default {
  name: 'ForgotPassword',
  components: {
    Header
  },
  data () {
    return {
      email: '',
      loading: false,
      success: false,
      error: ''
    }
  },
  methods: {
    async forgotPassword () {
      this.error = ''
      this.success = false
      this.loading = true
      try {
        await API.forgotPassword(this.email)
        this.success = true
      } catch (e) {
        this.error = formatAPIErr(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.error {
  color: red;
}
</style>
