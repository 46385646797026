<template>
  <LearningLayout @theme="theme= $event" @pack="pack = $event">
    <Loading v-if="!theme || apiLoading" />
    <div v-else class="center-container">
      <LearningMenu :theme="theme" />
      <LearningSettingsMenu :theme="theme" />
      <EmailFormInvite v-if="client && pack" :client="client" :pack="pack">
        <h3 slot="intro">{{ $t('titleInviteLearningTemplate') }}</h3>
      </EmailFormInvite>
    </div>
  </LearningLayout>
</template>

<script>
import LearningLayout from '@/components/LearningLayout'
import LearningMenu from '@/components/LearningMenu'
import LearningSettingsMenu from '@/components/LearningSettingsMenu'

import EmailFormInvite from '@/components/emails/EmailFormInvite'
import Loading from '@/components/Loading'
import { API } from '@/API'

export default {
  name: 'LearningSettingsInvite',
  components: {
    Loading,
    LearningLayout,
    LearningSettingsMenu,
    LearningMenu,
    EmailFormInvite
  },
  data () {
    return {
      theme: null,
      pack: null,
      client: null,
      apiLoading: true
    }
  },
  async mounted () {
    this.pack = await API.getPack(this.$route.params.packId)
    this.client = await API.getClient(this.pack.clientUUID)
    this.apiLoading = false
  }
}
</script>
