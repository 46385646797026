<template>
  <Loading v-if="!moduleProgress" />
  <div
    v-else
    class="module"
  >
    <h3 :id="module.uuid">
      <span>{{ module.name }}</span>
      <div
        v-if="module.mandatory"
        class="mandatory"
      >
        {{ $t('recordsMandatory') }}
      </div>
      <div
        v-else
        class="facultative"
      >
        {{ $t('recordsFacultative') }}
      </div>
    </h3>
    <table class="info-record">
      <tr>
        <td>{{ $t('identifier') }}</td>
        <td>
          {{ module.key }}
        </td>
      </tr>
      <tr>
        <td>{{ $t('recordsUsers') }}</td>
        <td>
          {{ moduleProgress.length }}<img
            class="user-picto"
            src="@/assets/user.svg"
          >
        </td>
      </tr>
      <tr>
        <td>{{ $t('recordsAverageScore') }}</td>
        <td>{{ average('score', moduleProgress) }}%</td>
      </tr>
      <tr>
        <td>{{ $t('recordsCompletions') }}</td>
        <td>
          {{ moduleProgress.filter((r) => r.progress === 100).length }}<img
            class="user-picto"
            src="@/assets/complete.svg"
          >
        </td>
      </tr>
      <tr>
        <td>{{ $t('recordsAverageTime') }}</td>
        <td><ElapsedTime :time="Math.round(average('elapsedTime', moduleProgress))" /></td>
      </tr>
    </table>
    <ModuleUsers
      :users-map="usersMap"
      :records="moduleProgress"
      class="record"
    />
    <div class="actions">
      <div v-if="selectFormat">
        <button
          class="button green"
          @click="startDownload('xlsx')"
        >
          Excel
        </button>
        <button
          class="button yellow"
          @click="startDownload('csv')"
        >
          CSV
        </button>
      </div>
      <button
        v-else
        class="button yellow"
        :disabled="loading"
        @click="selectFormat = true"
      >
        {{ loading ? 'Loading' : 'Export' }}
      </button>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import ModuleUsers from './ModuleUsers'
import ElapsedTime from '@/components/data/ElapsedTime'
import { API } from '@/API'

export default {
  components: {
    Loading,
    ElapsedTime,
    ModuleUsers
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    usersMap: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      moduleProgress: null,
      selectFormat: false,
      loading: false
    }
  },
  async mounted () {
    this.moduleProgress = await API.getModuleProgress(this.module.uuid)
  },
  methods: {
    average (key, array) {
      if (!array || !array.length) {
        return 0
      }
      let res = 0
      for (const e of array) {
        res += e[key]
      }
      return (res / array.length).toFixed(2)
    },
    async startDownload (fmt) {
      this.loading = true
      this.selectFormat = false
      try {
        await API.downloadModuleProgressExport(this.module.uuid, fmt)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
h3 {
  font-size: 24px;
  margin-bottom: 40px;
}

.info-record {
  width: 100%;
}
.info-record td {
  padding-bottom: 15px;
}
.info-record > tr > td:nth-child(1) {
  width: 20%;
}
.info-record > tr > td:nth-child(2) {
  width: 40%;
}
.record {
  margin-top: 40px;
}
hr {
  margin-top: 60px;
  margin-bottom: 60px;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & .button {
    margin: 0;
  }

  & > *:not(:last-child) {
    margin-right: 10px;
  }
}
</style>
