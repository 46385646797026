<template>
  <tr>
    <td>{{ token.label }}</td>
    <td>{{ token.username }}</td>
    <td><Time :time="token.lastUsedAt" /></td>
    <td>
      <button class="button red action-btn" :disabled="deleteLoading" :title="$t('delete')" @click="deleteToken">
        <Loading v-if="deleteLoading" class="small-loading loading-white" />
        <img v-else class="action-icon" src="@/assets/trash.svg">
      </button>
    </td>
  </tr>
</template>

<script>
import Time from '@/components/data/Time'
import { API } from '@/API'

export default {
  name: 'Token',
  components: {
    Time
  },
  props: {
    token: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      deleteLoading: false,
      deleteError: null
    }
  },
  methods: {
    async deleteToken () {
      await this.$dialog.confirm(this.$t('confirmTokenDeletion'), {
        type: 'hard',
        verification: this.token.label,
        verificationHelp: this.$t('confirmHelp'),
        okText: this.$t('confirmOk'),
        cancelText: this.$t('confirmCancel')
      })

      this.deleteLoading = true
      this.deleteError = null
      try {
        await API.deleteAPIToken(this.token.uuid)
        this.$emit('delete-token')
      } catch (e) {
        alert(e.toString())
      } finally {
        this.deleteLoading = false
      }
    }
  }
}
</script>

<style scoped>
.error {
  color: red;
}
</style>
