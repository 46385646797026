<template>
  <form class="form" @submit.prevent="submit">
    <div class="field">
      <label class="bold" for="reminder-delay">
        {{ $t('reminderRuleDelay') }}
      </label>
      <input
        id="reminder-delay"
        v-model="form.delayDay"
        name="duration"
        type="number"
        min="1"
        max="3650"
        required
      >
    </div>
    <div class="field">
      <label class="bold" for="reminder-learning-status">
        {{ $t('reminderRuleLearningStatus') }}
      </label>
      <Multiselect
        id="reminder-learning-status"
        v-model="form.learningStatus"
        :multiple="true"
        :options="options"
        label="label"
        track-by="value"
        required
      />
    </div>
    <div class="field">
      <label class="bold" for="reminder-template">
        {{ $t('reminderRuleTemplate') }}
      </label>
      <select id="reminder-template" v-model="form.reminderTemplateId" required>
        <option disabled value="">{{ $t('templateSelectPlaceholder') }}</option>
        <option v-for="template of templates" :key="template.id" :value="template.uuid">
          {{ template.name }}
        </option>
      </select>
    </div>
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <button class="button submit btn-m green" :disabled="loading">
      {{ loading ? $t('loading') : $t('save') }}
    </button>
  </form>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { API } from '@/API'
import Multiselect from 'vue-multiselect'
import { translateLearningStatus } from '@/utils/translation'

export default {
  name: 'ReminderRuleCreate',
  components: {
    Multiselect
  },
  props: {
    packId: {
      type: String,
      required: true
    },
    templates: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      error: null,
      form: {
        delayDay: 0,
        learningStatus: [],
        reminderTemplateId: ''
      }
    }
  },
  computed: {
    options () {
      const option = (s) => ({ label: translateLearningStatus(k => this.$t(k), s), value: s })
      return [
        option('initial'),
        option('in progress')
      ]
    }
  },
  watch: {
    'form.learningStatus' (updated, prev) {
      if (prev.length === 0 && updated.length > 0) {
        const lsInput = document.querySelector('#reminder-learning-status')
        lsInput.setCustomValidity('')
      }
    }
  },
  methods: {
    async submit () {
      const { form } = this
      if (form.learningStatus.length === 0) {
        const lsInput = document.querySelector('#reminder-learning-status')
        lsInput.setCustomValidity(this.$t('requiredField'))
        return
      }
      this.loading = true
      try {
        const rule = await API.createReminderRule({
          packId: this.packId,
          delayHour: parseInt(form.delayDay) * 24,
          learningStatus: form.learningStatus.map(s => s.value),
          reminderTemplateId: form.reminderTemplateId
        })
        this.$toast.success(this.$t('reminderRuleSaved'))
        this.$emit('created', rule)
      } catch (e) {
        console.error(e)
        this.error = `${e.response.status}: ${JSON.stringify(e.response.data)}`
        Sentry.captureException(e, { tag: 'apiResponse', route: 'create-reminder-rule' })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.form {
  margin-top: 20px;
}

label {
  cursor: pointer;
  display: block;
  margin-bottom: 5px;
}

.field {
  margin-bottom: 20px;

  & input,
  & select {
    width: 100%;
    box-sizing: border-box;
  }
}

.error {
  color: red;
  margin-bottom: 10px;
}
</style>
