<template>
  <LearningLayout class="client" @theme="theme= $event" @pack="onPack">
    <Loading v-if="!client || !users || !modules || !pack" />
    <template v-else>
      <div class="center-container">
        <LearningMenu :theme="theme" />
        <PackActions
          :client="client"
          :pack="pack"
          :progress="progress"
          :users-map="usersMap"
          :add-users="addUsers"
        />
      </div>
      <div class="center-container">
        <table class="info">
          <tr>
            <td>{{ $t('recordsClient') }}</td>
            <td>{{ client.name }}</td>
          </tr>
          <tr>
            <td>{{ $t('recordsName') }}</td>
            <td>{{ pack.key }}</td>
          </tr>
          <tr>
            <td>{{ $t('loginMode') }}</td>
            <td v-if="pack.loginMode === 'email'">
              {{ $t('loginModeEmail') }}
            </td>
            <td v-if="pack.loginMode === 'token'">
              {{ $t('loginModeToken') }}
            </td>
          </tr>
          <tr v-if="pack.loginMode === 'email'">
            <td>URL</td>
            <td>
              <a
                :href="pack.url"
                target="_blank"
                rel="noopener noreferrer"
              >{{ pack.url }}</a>
            </td>
          </tr>
          <tr v-if="$store.getters.isAdmin && datoURL">
            <td>Dato URL</td>
            <td>
              <a
                :href="datoURL"
                target="_blank"
              >
                {{ datoURL }}
              </a>
            </td>
          </tr>
          <tr>
            <td>{{ $t('recordsUsers') }}</td>
            <td>
              {{ users.length }}<img
                class="user-picto"
                src="@/assets/user.svg"
              >
            </td>
          </tr>
          <tr>
            <td>{{ $t('recordsModules') }}</td>
            <table>
              <tr
                v-for="(m, idx) of modules"
                :key="m.uuid"
                class="module"
              >
                <td>
                  <p>
                    {{ idx + 1 }} - <router-link
                      :to="{ name: 'module-detail', params: { moduleId: m.uuid } }"
                    >
                      {{ m.name }}
                    </router-link>
                  </p>
                </td>
                <td class="module-actions">
                  <div
                    v-if="m.mandatory"
                    class="mandatory"
                  >
                    {{ $t('recordsMandatory') }}
                  </div>
                  <div
                    v-else
                    class="facultative"
                  >
                    {{ $t('recordsFacultative') }}
                  </div>
                  <button
                    class="button red action-btn"
                    :disabled="loadingDeleteModule[m.uuid]"
                    @click="deleteModule(m.uuid)"
                  >
                    <Loading
                      v-if="loadingDeleteModule[m.uuid]"
                      class="small-loading loading-white"
                    />
                    <img
                      v-else
                      class="action-icon"
                      src="../assets/trash.svg"
                    >
                  </button>
                </td>
              </tr>
            </table>
          </tr>
          <tr>
            <td>{{ $t('recordsLanguages') }}</td>
            <td>
              <p
                v-for="c of client.languages"
                :key="c"
                class="lang"
              >
                {{ langText(c) }}
              </p>
            </td>
          </tr>
          <tr>
            <td>{{ $t('recordsEntities') }}</td>
            <td><Entities :users="users" /></td>
          </tr>
        </table>
        <PackUsers
          v-if="theme"
          :pack="pack"
          :client="client"
          :theme="theme"
          :users-map="usersMap"
          :learning-url="pack.url"
          :progress="progress"
          @deleted="deleteUser"
        />
      </div>
    </template>
  </LearningLayout>
</template>

<script>
import LearningLayout from '@/components/LearningLayout'
import LearningMenu from '@/components/LearningMenu'
import Entities from '@/components/pack-detail/Entities'
import PackUsers from '@/components/pack-detail/PackUsers'
import Loading from '@/components/Loading'
import PackActions from '@/components/pack-detail/PackActions'
import langs from 'langs'
import { API } from '@/API'

export default {
  name: 'RecordsView',
  components: {
    LearningLayout,
    LearningMenu,
    Loading,
    Entities,
    PackActions,
    PackUsers
  },
  data () {
    return {
      showCsvImport: false,
      pack: null,
      client: null,
      users: null,
      modules: null,
      theme: null,
      progress: null,
      loadingDeleteModule: {}
    }
  },
  computed: {
    datoURL () {
      if (!this.pack) {
        return null
      }
      return `https://opo-gdpr.admin.datocms.com/editor/item_types/33671/items/${this.pack.datoModuleId}/edit`
    },
    usersMap () {
      if (!this.users) {
        return null
      }
      const map = {}
      for (const user of this.users) {
        map[user.uuid] = user
      }
      return map
    },
    progressMap () {
      if (!this.progress) {
        return null
      }
      const map = {}
      for (const progress of this.progress) {
        map[progress.userUUID] = progress
      }
      return map
    }
  },
  async mounted () {
    const { packId } = this.$route.params
    return Promise.all([
      API.getUsers(packId).then(users => { this.users = users }),
      API.getModules(packId).then(modules => { this.modules = modules }),
      API.getPackProgress(packId).then(progress => { this.progress = progress })
    ])
  },
  methods: {
    async onPack (pack) {
      this.pack = pack
      this.client = await API.getClient(this.pack.clientUUID)
    },
    langText (code) {
      return langs.where('1', code.split('-')[0]).name
    },
    onDeleteModule (moduleUUID) {
      this.modules = this.modules.filter(m => m.uuid !== moduleUUID)
    },
    addUsers (users) {
      const tmpProgress = this.progress.slice()
      const tmpUsers = this.users.slice()
      for (const user of users) {
        const existingUser = this.usersMap[user.uuid]
        if (existingUser) {
          const idx = tmpUsers.indexOf(existingUser)
          tmpUsers[idx] = user
        } else {
          tmpUsers.push(user)
        }
        const existingProgress = this.progressMap[user.uuid]
        if (!existingProgress) {
          tmpProgress.push({
            userUUID: user.uuid,
            status: 'initial',
            score: 0,
            progress: 0,
            elapsedTime: 0
          })
        }
      }
      // Set tmp after iterating to reduce overhead
      // Because on each push on Vue's variable, Vue rerun watchers (progressMap & usersMap)
      this.progress = tmpProgress
      this.users = tmpUsers
    },
    deleteUser (userUUID) {
      this.progress = this.progress.filter(p => p.userUUID !== userUUID)
      this.users = this.users.filter(u => u.uuid !== userUUID)
    },
    async deleteModule (moduleUUID) {
      try {
        const module = this.modules.find(m => m.uuid === moduleUUID)
        const confirmMsg = this.$t('confirmModuleDeletion')
        await this.$dialog.confirm(confirmMsg, {
          type: 'hard',
          verification: module.key,
          verificationHelp: this.$t('confirmHelp'),
          okText: this.$t('confirmOk'),
          cancelText: this.$t('confirmCancel')
        })
      } catch (e) {
        return
      }
      this.$set(this.loadingDeleteModule, moduleUUID, true)
      try {
        await API.deleteModule(moduleUUID)
        this.modules = this.modules.filter(m => m.uuid !== moduleUUID)
      } catch (e) {
        console.error('deleteModule failed', e)
      } finally {
        this.$delete(this.loadingDeleteModule, moduleUUID)
      }
    }
  }
}
</script>

<style scoped>
::v-deep h2 {
  text-align: center;
  font-size: 45px;
}

::v-deep h3 {
  font-size: 24px;
}

.client {
  text-align: left;
  padding-bottom: 20px;
}
.info td {
  padding: 0;
}
.info > tr > td:nth-child(1) {
  min-width: 150px;
  font-weight: bold;
  font-size: 18px;
}
.module p {
  margin: 0;
}
.logo {
  max-width: 200px;
  max-height: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
}
td {
  vertical-align: top;
}
.info {
  border-spacing: 10px;
}

.button.refresh {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  font-size: 15px;
  padding: 5px 10px;

  & > img {
    width: 20px;
    margin-right: 10px;
  }
}
.lang {
  margin-top: 0;
}

.module-actions {
  display: flex;
  align-items: center;

  & .button {
    margin-left: 5px;
    padding: 4px 25px;
  }
}

.disabled-wait:disabled {
  cursor: wait;
}
</style>
